<template>
  <v-app>
    <SideMenu submenuPage="Packages">
      <Alert :alert_type="alert_type" v-if="alert_condition">{{
        alert_message
      }}</Alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs v-model="tab">
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#services">Services</v-tab>
            <v-tab href="#resources">Resources</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
          </v-tabs>
        </template>

        <template v-slot:form>
          <div class="selector_container" v-if="tab === 'services'">
            <multiselect v-model="value" :options="serviceOptions" track-by="dname" label="dname" :show-labels="false"
              placeholder="Select service">
              <template slot="option" slot-scope="props">
                <div >
                  <span v-if="props.option.serviceId" class="ml-3">{{ props.option.dname }}</span>
                  <span v-if="!props.option.serviceId" class="font-weight-bold">{{ props.option.dname }}</span>
                </div>
              </template>
            </multiselect>
            <v-btn color="primary" class="mr-4" @click="addService">
              + add
            </v-btn>
          </div>

          <div class="selector_container" v-if="tab === 'resources'">
            <multiselect v-model="value2" :options="resOptions" track-by="dname" label="dname" :show-labels="false"
              placeholder="Select resource">
              <template slot="option" slot-scope="props">
                <div>
                  <span v-if="props.option.resourceId" class="ml-3">{{ props.option.dname }}</span>
                  <span v-if="!props.option.resourceId" class="font-weight-bold">{{ props.option.dname }}</span>
                </div>
              </template>
            </multiselect>
            <v-btn color="primary" class="mr-4" @click="addRes">
              + add
            </v-btn>
          </div>

          <v-tabs-items v-model="tab">

            <v-tab-item value="details">
              <v-form ref="form" lazy-validation>
                <p class="m-0">Package Name</p>
                <v-text-field v-model="pkg.name" :counter="191" :rules="nameRules.concat(characterRules)"
                  required></v-text-field>

                <p class="m-0">Price</p>
                <v-text-field v-model="pkg.price" type="number" class="v-text-field-number" step="0.01" min="0"
                  oninput="validity.valid||(value='');"></v-text-field>

                <p>Status</p>
                <v-select v-model="pkg.status" :items="pkgStatus" outlined class="w-50"></v-select>

                <p class="m-0">Expiry Days<small> (0 represents as permanent)</small></p>
                <v-text-field v-model="pkg.duration_days" type="number" class="v-text-field-number" step="1" min="0" suffix="days"></v-text-field>

                <p>Description</p>
                <v-textarea rows="3" v-model="pkg.description" :counter="500" :rules="descRules" outlined></v-textarea>
              </v-form>
            </v-tab-item>

            <v-tab-item value="services">
              <v-data-table :headers="headers" :items="servicesInPackages" class="elevation-1" item-key="id" :sort-by="['id']" :sort-desc="[true]">
                <template v-slot:item="{ item }">
                  <tr style="cursor:pointer">
                    <td>{{ item.typeName }}</td>
                    <td>{{ item.serviceName }}</td>
                    <td class="amount_input"> <input type="number" v-model="item.amount"> </td>
                    <td class="text-center"><v-icon class="deleteBtnIcon"
                        @click="removeItem(item)">mdi-close-circle</v-icon></td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>

            <v-tab-item value="resources">
              <v-data-table :headers="resHeaders" :items="resourceInPackages" class="elevation-1" item-key="id" :sort-by="['id']" :sort-desc="[true]">
                <template v-slot:item="{ item }">
                  <tr style="cursor:pointer">
                    <td>{{ item.resTypeName }}</td>
                    <td>{{ item.resName }}</td>
                    <td class="amount_input"> <input type="number" v-model="item.amount"> </td>
                    <td class="text-center"><v-icon class="deleteBtnIcon"
                        @click="removeResItem(item)">mdi-close-circle</v-icon></td>
                  </tr>
                </template>
              </v-data-table>
            </v-tab-item>

            <v-tab-item value="outlet">
              <v-form ref="form2" lazy-validation>
                <v-container fluid class="pt-0 pb-0">
                  <v-checkbox v-model="selectAllOutlets" :label="'All Outlets'" @change="selectOutlets"></v-checkbox>
                  <v-checkbox v-model="selectedOutlets" v-for="outlet in outlets" :key="outlet.id" :label="outlet.name"
                    :value="outlet.id" @change="detectAllOrNot"></v-checkbox>
                </v-container>
              </v-form>
            </v-tab-item>
            
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn class="mr-4" text @click="$router.push({ path: '/packages' })">
            Back to list
          </v-btn>
          <v-btn color="success" v-if="isNext" class="mr-4" @click="validateTab(tab)">
            Next
          </v-btn>
          <v-btn color="success" v-if="!isNext" class="mr-4" @click="validate">
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import Alert from "/src/components/Alert";
import Multiselect from 'vue-multiselect'
import gql from 'graphql-tag'
const GET_ALL_SERVICE_TYPES_QUERY = gql`
        query ServiceTypes {
          serviceTypes {
            id
            name
          }
        }
    `
const GET_ALL_SERVICE_QUERY = gql`
      query Services {
        services {
          id
          name
          service_type_id
        }
      }
  `
const GET_ALL_OUTLETS_QUERY = gql`
        query Outlets {
          outlets {
            id
            name
            created_at
          }
        }
    `
const GET_ALL_STAFFS_QUERY = gql`
        query Staffs {
          staffs {
            id
            fullname
          }
        }
    `
const GET_ALL_RESOURCESTYPES_QUERY = gql`
      query ResourceTypes {
        resourceTypes {
          id
          name
        }
      }
  `
const GET_ALL_RESOURCES_QUERY = gql`
      query Resources {
        resources {
          id
          name
          resource_type_id
      }
    }
  `
export default {
  name: 'Packages',

  components: {
    SideMenu,
    Multiselect,
    Form,
    Alert
  },

  data() {

    return {
      nameRules: [
        v => !!v || 'Name is required',
      ],
      characterRules: [
        v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
      ],
      descRules: [
        v => (v.length <= 500) || 'Field must be less than or equal to 500 characters',
      ],
      durationRules: [
        v => v != 0 || 'Duration has to be at least 1 minute',
      ],
      serviceTypes: [],
      services: [],
      resourceTypes: [],
      resources: [],
      outlets: [],
      staffs: [],
      serviceOptions: [],
      resOptions: [],
      selectedOutlets: [],
      selectAllOutlets: false,
      tab: null,
      pkgStatus: ["draft", "published"],
      pkg: {
        name: "",
        price: "",
        description: "",
        status: "draft",
        duration_days: 0
      },
      value:
      {
        dname: "Select service",
        typeId: "",
        typeName: "",
        serviceId: "",
        serviceName: "",
        id: "",
        name: "",
        service_type_id: "",
      },
      value2: {
        dname: "Select resource",
        typeId: "",
        typeName: "",
        resourceId: "",
        resourceName: "",
        id: "",
        name: "",
        resource_type_id: "",
      },
      servicesInPackages: [],
      headers: [
        {
          text: 'Service Type',
          value: 'typeName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Service Name',
          value: 'serviceName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Amount',
          value: 'amount',
          class: 'white--text', width: '15%'
        },
        {
          text: 'Remove',
          value: 'remove',
          class: 'white--text', width: '15%'
        }
      ],
      resourceInPackages: [],
      resHeaders: [
        {
          text: 'Resource Type',
          value: 'resTypeName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Resource Name',
          value: 'resName',
          class: 'white--text', width: '35%'
        },
        {
          text: 'Amount',
          value: 'amount',
          class: 'white--text', width: '15%'
        },
        {
          text: 'Remove',
          value: 'remove',
          class: 'white--text', width: '15%'
        }
      ],
      packageList: [],
      serviceList: [],
      resList: [],
      alert_type: "",
      alert_message: "",
      alert_condition: false,
      isNext: true,
    }
  },
  apollo: {
    serviceTypes: {
      query: GET_ALL_SERVICE_TYPES_QUERY,
      result() {
        this.changeServices()
      }
    },
    services: {
      query: GET_ALL_SERVICE_QUERY,
      result() {
        this.changeServices()
      }
    },
    resourceTypes: {
      query: GET_ALL_RESOURCESTYPES_QUERY,
      result() {
        this.changeRes()
      }
    },
    resources: {
      query: GET_ALL_RESOURCES_QUERY,
      result() {
        this.changeRes()
      }
    },
    outlets: {
      query: GET_ALL_OUTLETS_QUERY,
    },
    staffs: {
      query: GET_ALL_STAFFS_QUERY,
    }
  },
  computed: {
    type() {
      // Compute the alert type based on the value of alertType
      switch (this.alert_type) {
        case 'success':
          return 'success';
        case 'error':
          return 'error';
        case 'warning':
          return 'warning';
        default:
          return 'info';
      }
    }
  },
  methods: {
    validate() {
      this.packageList = []
      for (const item of this.servicesInPackages) {
        for (let i = 0; i < parseInt(item.amount); i++) {
          const newItem = {
            service_type_id: item?.typeId ? item.typeId : null,
            service_id: item?.serviceId ? item.serviceId : null,
            resource_type_id: item?.resTypeId ? item?.resTypeId : null,
            resource_id: item?.resId ? item?.resId : null,
          }
          this.serviceList.push(newItem)
        }
      }
      for (const item of this.resourceInPackages) {
        for (let i = 0; i < parseInt(item.amount); i++) {
          const newItem = {
            service_type_id: item?.typeId ? item.typeId : null,
            service_id: item?.serviceId ? item.serviceId : null,
            resource_type_id: item?.resTypeId ? item?.resTypeId : null,
            resource_id: item?.resId ? item?.resId : null,
          }
          this.resList.push(newItem)
        }
      }
      this.packageList = [...this.serviceList, ...this.resList]

      var validate = this.$refs.form.validate()
      if (!validate) {
        window.scrollTo(0, 0);
        this.tab = "details"
      }
      else if (this.packageList.length == 0) {
        window.scrollTo(0, 0);
        this.alert_type = "error";
        this.alert_message = "At least pick one from service and resource";
        this.alert_condition = true;
        setTimeout(() => {
          this.alert_condition = false;
        }, 5000);
      }
      else if (this.selectedOutlets.length == 0) {
        window.scrollTo(0, 0);
        this.tab = "outlet"
        return
      }
      if (validate && this.packageList.length > 0) {
        const CREATE_PACKAGE_MUTATION = gql`
          mutation CreatePackage($name: String!, $description: String, $price: Float!, $status: packageStatus!,$duration_days: Int) {
            createPackage(name: $name, description: $description, price: $price, status: $status, duration_days: $duration_days) {
              id
              package_name
              description
              price
              status
              created_at
              updated_at
              duration_days
            }
          }
        `;

        const CREATE_PACKAGE_DETAILS = gql`
          mutation CreatePackageDetails($packageId: Int!, $serviceTypeId: Int, $serviceId: Int, $resourceTypeId: Int, $resourceId: Int) {
            createPackageDetails(packageId: $packageId, serviceTypeId: $serviceTypeId, serviceId: $serviceId, resourceTypeId: $resourceTypeId, resourceId: $resourceId) {
              id
              package_id
            }
          }
        `;

        const CREATE_PACKAGE_OUTLET = gql`
          mutation CreatePackageOutlet($packageId: Int!, $outletId: Int!) {
            createPackageOutlet(packageId: $packageId, outletId: $outletId) {
              id
              package_id
              outlet_id
            }
          }
        `;

        let packageId;
        this.$apollo.mutate({
          mutation: CREATE_PACKAGE_MUTATION,
          variables: {
            name: this.pkg.name,
            description: this.pkg.description,
            price: this.pkg.price * 1,
            status: this.pkg.status,
            duration_days: this.pkg.duration_days * 1
          },
        })
          .then(createPackageResponse => {
            packageId = createPackageResponse.data.createPackage.id;

            for (let i = 0; i < this.packageList.length; i++) {
              const item = this.packageList[i];

              this.$apollo.mutate({
                mutation: CREATE_PACKAGE_DETAILS,
                variables: {
                  packageId: parseInt(packageId),
                  serviceTypeId: parseInt(item.service_type_id),
                  serviceId: parseInt(item.service_id),
                  resourceTypeId: parseInt(item.resource_type_id),
                  resourceId: parseInt(item.resource_id),
                },
              });
            }

            for (let i = 0; i < this.selectedOutlets.length; i++) {
              const outletId = parseInt(this.selectedOutlets[i]);

              this.$apollo.mutate({
                mutation: CREATE_PACKAGE_OUTLET,
                variables: {
                  packageId: parseInt(packageId),
                  outletId: parseInt(outletId),
                },
              });
              this.$router.push({ name: 'Packages', params: { alert: true, alert_type: 'success', message: ' created successfully' } })
            }

          })
          .catch(error => {
            console.error(error);
          });
      }

    },
    addService() {
      if (this.value?.id) {
        const selectedService = { ...this.value }
        selectedService.amount = 1

        // Check if the selected service already exists in the array
        const existingService = selectedService.serviceId ? this.servicesInPackages.find(service => service.serviceId == selectedService.serviceId) : this.servicesInPackages.find(service => service.typeId == selectedService.typeId && !service.serviceId)
        if (existingService) {
          // Item already exists, you can handle this case as per your requirement
        } else {
          // Item doesn't exist, add it to the array
          this.servicesInPackages.push(selectedService)
        }

        this.value = null;
      }
    },
    removeItem(item) {
      // Implement the logic to remove the item from the servicesInPackages array
      const index = this.servicesInPackages.indexOf(item);
      if (index > -1) {
        this.servicesInPackages.splice(index, 1);
      }
    },
    changeServices() {
      const options = [];
      const types = [...this.serviceTypes];
      const serviceList = [...this.services];
      const addedServiceIds = new Set();

      types.forEach(serviceType => {
        serviceType.typeId = serviceType.id;
        serviceType.typeName = serviceType.name;
        serviceType.dname = serviceType.name;
        serviceType.serviceId = ""
        serviceType.serviceName = ""
        serviceType.group = 'type'
        options.push(serviceType);
       

        serviceList.forEach(service => {
          if (
            parseInt(service.service_type_id) === parseInt(serviceType.id) &&
            !addedServiceIds.has(service.id) // check service id in Set
          ) {
            service.typeId = serviceType.id;
            service.typeName = serviceType.name;
            service.serviceId = service.id
            service.serviceName = service.name
            service.dname = `${service.name} (${serviceType.name})`;
            serviceType.group =  'specific'
            options.push(service);
            addedServiceIds.add(service.id); // add service id into Set
          }
        });
      });
      this.serviceOptions = options
    },
    addRes() {
      if (this.value2?.id) {
        const selectedRes = { ...this.value2 }
        selectedRes.amount = 1

        // Check if the selected service already exists in the array
        const existingRes = selectedRes.resId ? this.resourceInPackages.find(res => res.resId == selectedRes.resId) : this.resourceInPackages.find(res => res.resTypeId == selectedRes.resTypeId && !res.resId)
        if (existingRes) {
          // Item already exists, you can handle this case as per your requirement
        } else {
          // Item doesn't exist, add it to the array
          this.resourceInPackages.push(selectedRes)
        }

        this.value2 = null;
      }
    },
    changeRes() {
      const options = [];
      const types = [...this.resourceTypes];
      const resList = [...this.resources];
      const addedResIds = new Set();

      types.forEach(resType => {
        resType.resTypeId = resType.id;
        resType.resTypeName = resType.name;
        resType.dname = resType.name;
        resType.resId = ""
        resType.resName = ""
        options.push(resType);

        resList.forEach(res => {
          if (
            parseInt(res.resource_type_id) === parseInt(resType.id) &&
            !addedResIds.has(res.id) // check service id in Set
          ) {
            res.resTypeId = resType.id;
            res.resTypeName = resType.name;
            res.resId = res.id
            res.resName = res.name
            res.dname = `${res.name} (${resType.name})`;
            options.push(res);
            addedResIds.add(res.id); // add service id into Set
          }
        });
      });
      this.resOptions = options
    },
    removeResItem(item) {
      // Implement the logic to remove the item from the servicesInPackages array
      const index = this.resourceInPackages.indexOf(item);
      if (index > -1) {
        this.resourceInPackages.splice(index, 1);
      }
    },
    selectOutlets() {
      if (this.selectAllOutlets) {
        this.selectedOutlets = this.outlets.map(o => o.id);
      } else {
        this.selectedOutlets = [];
      }
    },
    detectAllOrNot() {
      if (this.selectedOutlets.length === this.outlets.length) {
        this.selectAllOutlets = true
      } else {
        this.selectAllOutlets = false
      }
    },
    validateTab(tab) {
      switch (tab) {
        case "details":
          var validate = this.$refs.form.validate();
          if (!validate) {
            window.scrollTo(0, 0);
          } else {
            this.tab = "services";
          }
          break;
        case "services":
          this.tab = "resources";
          break;
        case "resources":
          this.tab = "outlet";
          this.isNext = false;
          break;
        default:
          this.isNext = false;
          break;
      }
    }
  }
}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>

<style>
.multiselect__content {
  padding-left: 0 !important;
}

.multiselect {
  margin-right: 5px;
}

.selector_container {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
}

.amount_input>input {
  width: 100%;
  text-align: center;
  outline: rgba(0, 0, 0, 0.2) auto 1px;
}

.amount_input>input:focus-visible {
  outline: -webkit-focus-ring-color auto 1px;
}


.v-select .v-select__selection  {
  text-transform: capitalize;
}
.v-menu_content .v-list-item_title::first-letter {
  text-transform: capitalize;
}
</style>