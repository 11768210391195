<template>
    <v-app>
      <SideMenu :alert="alert" :alert_type="alert_type" :message="message"  submenuPage="Sales Listing"
            submenuGroup="Listing">
        <Form>
          <template v-slot:tabs>
            <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
          </v-tabs>
          </template>
          <template v-slot:form>
            <v-tabs-items v-model="tab">
                <v-tab-item value="details">
                    <v-row>
                        <v-col cols="3">Sales id: </v-col>
                        <v-col cols="6">{{ saleDetails.id }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Sales Type: </v-col>
                        <v-col cols="6">{{ saleDetails.sales_type }}</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Status: </v-col>
                        <v-col cols="6">{{ saleDetails.status }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.User">
                        <v-col cols="3">Customer: </v-col>
                        <v-col cols="6" @click="routeToCustomer(saleDetails.user_id)">{{ saleDetails.User.user_fullname }}({{ saleDetails.User.user_contact }})</v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="3">Booking Online: </v-col>
                        <v-col cols="6">{{ saleDetails.online_booking }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.staff">
                        <v-col cols="3">Staff: </v-col>
                        <v-col cols="6" @click="routeToStaff(saleDetails.staff_id)" >{{ saleDetails.staff.fullname }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.package_id">
                        <v-col cols="3">Packages: </v-col>
                        <v-col cols="6"><span @click="routeToPackage(p.id)" v-for="(p, i) of saleDetails.package_details" :key="i">{{p.name}}<span v-if="i !== saleDetails.package_details.length - 1">,</span></span></v-col>
                    </v-row>
                    <v-row v-if="saleDetails.booking_id">
                        <v-col cols="3">Booking: </v-col>
                        <v-col cols="6"><span @click="routeToBooking(p)" v-for="(p, i) of saleDetails.booking_id" :key="i">{{p}}<span v-if="i !== saleDetails.booking_id.length - 1">,</span></span></v-col>
                    </v-row>
                    <v-row v-if="saleDetails.invoice_no">
                        <v-col cols="3">Invoice No: </v-col>
                        <v-col cols="9">{{ saleDetails.invoice_no }}</v-col>
                        <v-col cols="3"></v-col>
                        <v-col cols="9">
                            <v-btn
                                small
                                color="primary"
                                class="mr-4 mt-1"
                                v-if="saleDetails.invoice_no"
                                @click="downloadInvoice(saleDetails.id)"
                            >
                            <v-icon left>
                                mdi-download
                            </v-icon>
                                Download
                            </v-btn>
                        </v-col>
                    </v-row>
                    <v-row v-if="saleDetails.payment_methods && saleDetails.payment_methods.length > 0">
                        <v-col cols="3">Payment Method: </v-col>
                        <v-col cols="6">
                            <template v-if="!saleDetails.split_payment"><span v-for="(p, i) of saleDetails.payment_methods" :key="i">{{ p.paymentMethod.name }}  RM {{ p.amount.toFixed(2) }}</span></template>
                            <template v-if="saleDetails.split_payment">
                                <v-simple-table class="table-border">
                                    <template v-slot:default>
                                    <thead>
                                        <tr>
                                        <th class="text-left">
                                            Name
                                        </th>
                                        <th class="text-left">
                                            Amount (RM)
                                        </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr v-for="item in saleDetails.payment_methods" :key="item.name">
                                        <td>{{ item.paymentMethod.name }}</td>
                                        <td>{{ item.amount.toFixed(2) }}</td>
                                        </tr>
                                    </tbody>
                                    </template>
                                </v-simple-table>
                                </template>
                        </v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">Outlet: </v-col>
                        <v-col cols="6" @click="routeToOutlet(saleDetails.outlet.id)" v-if="saleDetails.Outlet">{{ saleDetails.Outlet.name }}</v-col>
                    </v-row>

                    <v-row>
                        <v-col cols="3">Created At: </v-col>
                        <v-col cols="6">{{ saleDetails.created_at | formatDateTime }}</v-col>
                    </v-row>
                    <v-row v-if="saleDetails.voided_at">
                        <v-col cols="3">Voided At: </v-col>
                        <v-col cols="6">{{ saleDetails.voided_at | formatDateTime }}</v-col>
                    </v-row>
                    
                </v-tab-item>
            </v-tabs-items>
          </template>
          <template v-slot:buttons>
            <v-spacer>
            </v-spacer>
            <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/listing/sales' })"
          >
            Back to list
          </v-btn>
          <v-btn
            class="mr-4"
            @click="voidSales"
            color="error"
            :disabled = "saleDetails.status === 'voided' || !staffPermission.voidSales"
          >
            Void #{{ route }}
          </v-btn>
          </template>
        </Form>
      </SideMenu>
    </v-app>
  </template>
  
<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
  import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
export default {
    name: 'CommissionDetails',

    components: {
        SideMenu,
        Form,
    },

    data() {
        return {
            route: this.$route.params.id,
            alert: this.$route.params.alert,
            alert_type: this.$route.params.alert_type,
            message: this.$route.params.message,
            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
            getCommissionListById: [],
            getAllSales: [],
            saleDetails: {},
            outlet_id: '',
            outlet_ids: [],
            processed_outlets: [],
            processed_staff: [],
            staff_id: "",
            tab: null,
            getAllPackages: [],
        }
    },
    apollo: {
        getAllPackages:{
            query: queries.GET_ALL_PACKAGES_QUERY,
        },
        sales: {
            query: queries.GET_SALE,
            variables() {
                return {
                   id: this.route
                }
            },
            result() {
                const list = {...this.sales}
                this.saleDetails = list
                console.log(this.saleDetails)
            }
        },
    },
    methods: {
        routeToOutlet(value){
            if(!this.staffPermission.viewOutlet) return
            this.$router.push({ path: '/outlet/' + value })
        },
        routeToBooking(value){
            if(!this.staffPermission.viewBooking) return
            this.$router.push({ path: '/booking/' + value })
        },
        routeToPackage(value){
            if(!this.staffPermission.viewPackage) return
            this.$router.push({ path: '/package/' + value })
        },
        routeToStaff(value){
            if(!this.staffPermission.viewStaff) return
            this.$router.push({ path: '/staff/' + value })
        },
        routeToCustomer(value){
            if(!this.staffPermission.viewCustomer) return
            this.$router.push({ path: '/user/' + value})
        },
        updateSalesList() {
            if (this.getAllPackages && this.getAllPackages.length > 0 && this.saleDetails ) {
                    if (this.saleDetails.package_id) {
                        this.saleDetails.package_details = [];
                        this.saleDetails.package_list = []
                        this.saleDetails.package_id.forEach((pId) => {
                            const foundPackage = this.getAllPackages.find((p) => p.id == pId);
                            if (foundPackage) {
                                this.saleDetails.package_details.push({
                                    id: foundPackage.id,
                                    name: foundPackage.package_name,
                                });
                                this.saleDetails.package_list.push(foundPackage.package_name)
                            }
                        });
                    }
            }
        },
        voidSales() {
            const VOID_SALES = gql`
            mutation voidSales($id:ID!){
                voidSales(id:$id)  {
                    id
                    voided_at
                }
            }
            `
            this.$apollo.mutate(
                {
                    mutation: VOID_SALES,
                    variables: {
                        id: this.route * 1,
                    },
                }
            ).then(result=>{
                console.log("voided",result)
                this.$router.push({name: 'Sales Listing', params:{alert:true, alert_type:'success', message:"sales #"+this.route+' voided successfully'}})
            }).catch(error=>{
                console.log(error)
            })
        },
        // deleteSales() {
        //     const DELETE_SALES = gql`
        //     mutation deleteSales($id:ID!){
        //         deleteSales(id:$id)  {
        //             id
        //             deleted_at
        //         }
        //     }
        //     `
        //     this.$apollo.mutate(
        //         {
        //             mutation: DELETE_SALES,
        //             variables: {
        //                 id: this.route * 1,
        //             },
        //         }
        //     ).then(result=>{
        //         console.log("delete",result)
        //     }).catch(error=>{
        //         console.log(error)
        //     })
        // },
        async downloadInvoice(id) {
            try {
                const result = await this.$apollo.query({
                    query: queries.GET_SALE,
                    variables: {
                        id: id * 1,
                    }
                })
                window.open("https://prod-app360.sfo3.digitaloceanspaces.com/" + result.data.sales.pdfFile.filename + ".pdf", '_blank');
            } catch (error) {
                console.log(error);
                console.log(JSON.stringify(error, null, 2));
            }
        }
    },
    filters: {
    formatDateTime(value) {
    const dateTime = new Date(value * 1);

    const year = dateTime.getFullYear();
    const month = dateTime.toLocaleString('en-US', { month: 'short' });
    const day = dateTime.getDate().toString().padStart(2, '0');

    let hours = dateTime.getHours();
    const minutes = dateTime.getMinutes().toString().padStart(2, '0');
    const period = hours >= 12 ? 'PM' : 'AM';

    // Convert hours to 12-hour format
    if (hours > 12) {
      hours -= 12;
    } else if (hours === 0) {
      hours = 12;
    }

    return `${day} ${month} ${year} ${hours}:${minutes}${period}`;
  },

        formatDate(value) {
            const date = new Date(value * 1);
            // Customize the date formatting based on your requirements
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: "Asia/Singapore",
            });
            return formattedDate;
        },
        formatTime(value) {
            const time = new Date(value * 1);
            // Customize the time formatting based on your requirements
            const formattedTime = time.toLocaleTimeString('en-US', {
                hour12: false,
                hour: 'numeric',
                minute: 'numeric',
                timeZone: "Asia/Singapore",
            });
            return formattedTime;
        },
        formatPrice(value) {
            return Number(value).toFixed(2);
        },
    },
    watch: {
        getAllPackages : {
            handler() {
                this.updateSalesList();
            },
            deep: true,
        },
        sales: {
            handler() {
                this.updateSalesList();
            },
            deep: true,
        },
    },
    created(){
    this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
      this.getAllPermissions = await result.data.getAllPermissions;
      this.$apollo.query(
        {
          query: queries.GET_STAFF_ROLES,
          variables: {
            staffId: this.$session.get("user_id") * 1
          }
        }
      ).then(async result => {
        this.getStaffRoles = await result.data.getStaffRoles;
        this.allPermissionsName = [];
        this.getStaffRoles.forEach(role => {
          role.role.RolePermission.forEach((item) => {
            const name = item.permission.name;
            this.allPermissionsName.push(name);
          });
        })
        this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
        this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
      })
    })
  },
}
</script>

<style>
.table-border {
  border: 1px solid #e0e0e0;
  border-collapse: collapse;
}

</style>