<template>
  <v-app>
    <SideMenu submenuPage="Staff">
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              label="Staff"
              :value="staffById.fullname"
              disabled
            ></v-text-field>

            <v-text-field
              label="Date"
              :value="$route.query.date"
              disabled
              v-if="$route.query.date"
            ></v-text-field>
            <v-text-field
              label="Day"
              :value="dates[$route.query.day*1]"
              disabled
              v-else
            ></v-text-field>

            <v-select
              v-model="staffrosterById.outlet_id"
              :items="outlets"
              item-value="id"
              item-text="name"
              label="Outlet"
              :rules="[() => !!staffrosterById.outlet_id || 'Outlet is required']"
            ></v-select>

            <div v-if="!staffrosterById.off_day">
              <v-menu
                ref="from_time_menu"
                v-model="from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.from_time"
                    label="From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[() => staffrosterById.from_time <= staffrosterById.to_time || 'From Time has to earlier than To Time']"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.from_time_menu.save(staffrosterById.from_time)"
                  :rules="[() => staffrosterById.from_time <= staffrosterById.to_time || 'From Time has to earlier than To Time']"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="to_time_menu"
                v-model="to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.to_time"
                    label="To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.to_time_menu.save(staffrosterById.to_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_from_time_menu"
                v-model="break_from_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.break_from_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.break_from_time"
                    label="Break From Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                    :rules="[() => staffrosterById.break_from_time <= staffrosterById.break_to_time || 'From Time has to earlier than To Time']"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.break_from_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_from_time_menu.save(staffrosterById.break_from_time)"
                ></v-time-picker>
              </v-menu>

              <v-menu
                ref="break_to_time_menu"
                v-model="break_to_time_menu"
                :close-on-content-click="false"
                :nudge-right="40"
                :return-value.sync="staffrosterById.break_to_time"
                transition="scale-transition"
                offset-y
                max-width="290px"
                min-width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-model="staffrosterById.break_to_time"
                    label="Break To Time"
                    prepend-icon="mdi-clock-time-four-outline"
                    readonly
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker
                  v-model="staffrosterById.break_to_time"
                  :allowed-minutes="allowedStep"
                  class="mt-4"
                  format="24hr"
                  @click:minute="$refs.break_to_time_menu.save(staffrosterById.break_to_time)"
                ></v-time-picker>
              </v-menu>
            </div>

            <v-checkbox
              v-model="staffrosterById.off_day"
              label="Off Day"
            ></v-checkbox>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/staffs', query:{'tab':'roster'} })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
  const GET_USER_QUERY = gql`
      query staffById($id: ID!) {
        staffById(id: $id) {
            fullname
        }
      }
  `
  const GET_USER_OUTLET_QUERY = gql`
      query staffoutlets($staff_id:Int){
        staffoutlets(staff_id:$staff_id){
          outlet_id
          outlet{
            name
          }
        }
      }
  `
  const GET_ROSTER_QUERY = gql`
      query staffrosterById($id: ID!) {
        staffrosterById(id: $id) {
            id
            staff_id
            outlet_id
            day
            date
            off_day
            from_time
            to_time
            break_to_time
            break_from_time
        }
      }
  `
  export default {
    name: 'Staff',

    components:{
      SideMenu,
      Form
    },

    data () {
      
      return {
        staffById:{
          fullname:''
        },
        staffoutlets: {
          staff_id:'',
          outlet_id:'',
          staff:{
            fullname:''
          },
          outlet:{
            name:''
          }
        },
        outlets:[],
        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        staffrosterById:{
          off_day:false,
          from_time:'',
          to_time:'',
          break_from_time:'',
          break_to_time:'',
          outlet_id:''
        },
        from_time_menu:false,
        to_time_menu:false,
        break_from_time_menu:false,
        break_to_time_menu:false,
        roster_id: this.$route.query.roster_id ?? 0
      }
    },
    apollo: {
      staffById: {
        query: GET_USER_QUERY,
        variables() {
          return{
            id: this.$route.query.staff_id*1
          }
        },
      },
      staffoutlets: {
        query: GET_USER_OUTLET_QUERY,
        variables() {
          return{
            staff_id: this.$route.query.staff_id*1
          }
        },
        result() {
          var outlets = []
          for(let i=0; i<this.staffoutlets.length; i++){
            outlets.push({'id':this.staffoutlets[i].outlet_id, 'name':this.staffoutlets[i].outlet.name})
          }
          this.outlets = outlets
        }
      },
      staffrosterById: {
        query: GET_ROSTER_QUERY,
        variables() {
          return{
            id: this.roster_id*1
          }
        },
        result() {
          if(this.roster_id*1){
            this.staff_id = this.staffrosterById.staff_id
            if(this.staffrosterById.date != null && this.staffrosterById.date>0){//if date is not null and is an integer
              this.staffrosterById.date = new Date(this.staffrosterById.date*1).toISOString().slice(0, 10)
            }
            if(this.staffrosterById.from_time && !this.staffrosterById.from_time.includes(':')){
              this.staffrosterById.from_time = ("0" + (new Date(1620691200000+this.staffrosterById.from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.from_time*1).getMinutes())).slice(-2)
              this.staffrosterById.to_time = ("0" + (new Date(1620691200000+this.staffrosterById.to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.to_time*1).getMinutes())).slice(-2)
              this.staffrosterById.break_from_time = ("0" + (new Date(1620691200000+this.staffrosterById.break_from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.break_from_time*1).getMinutes())).slice(-2)
              this.staffrosterById.break_to_time = ("0" + (new Date(1620691200000+this.staffrosterById.break_to_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.staffrosterById.break_to_time*1).getMinutes())).slice(-2)
            }
          }
          if(!this.staffrosterById){
            this.staffrosterById = {
              off_day:false,
              from_time:'',
              to_time:'',
              break_from_time:'',
              break_to_time:'',
              outlet_id:''
            } 
          }
          if(this.staffrosterById.outlet_id == ''){
            if(this.outlets){
              this.staffrosterById.outlet_id = this.outlets[0].id
            }
          }
        }
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const CREATE_ROSTER_QUERY = gql`
            mutation createStaffRoster($staff_id:Int!, $day:Int, $date:String, $off_day:Boolean, $from_time:String!, $to_time:String!, $break_to_time:String, $break_from_time:String, $outlet_id:Int!){
              createStaffRoster(staff_id:$staff_id, day:$day, date:$date, off_day:$off_day, from_time:$from_time, to_time:$to_time, break_to_time:$break_to_time, break_from_time:$break_from_time, outlet_id:$outlet_id){
                date
                day
                from_time
                to_time
              }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_ROSTER_QUERY, 
              variables:{
                staff_id:this.$route.query.staff_id*1,
                day:this.$route.query.day*1,
                date:this.$route.query.day?null:this.$route.query.date,
                off_day:this.staffrosterById.off_day,
                from_time:this.staffrosterById.from_time,
                to_time:this.staffrosterById.to_time,
                break_from_time:this.staffrosterById.break_from_time,
                break_to_time:this.staffrosterById.break_to_time,
                outlet_id:this.staffrosterById.outlet_id
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createStaffRoster != null){ 
              console.log("Create successfully")
              this.$router.push({name: 'Staff', params:{alert:true, alert_type:'success', message:'Staff Roster created successfully'}, query:{'tab':'roster'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
      allowedStep: m => m % 5 === 0,
    }
  }
</script>
