import { gql } from 'graphql-tag';

export const GET_STAFF_ROLES = gql`
  query GetStaffRoles($staffId: Int!) {
    getStaffRoles(staffId: $staffId) {
      staffId
      role {
        id
        name
        RolePermission {
          permissionId
          permission {
            id
            name
          }
        }
      }
    }
  }
`;

export const GET_ALL_PERMISSIONS_QUERY = gql`
  query getAllPermissions {
    getAllPermissions {
      id
      name
      description
      category {
        name
      }
    }
  }
`;

export const GET_ALL_COMMISSION = gql`
query getCommissionList( $staff_id: Int, $outlet_id: Int, $startDate: String, $endDate: String) {
  getCommissionList( staff_id: $staff_id, outlet_id: $outlet_id, startDate: $startDate, endDate: $endDate) {
      id
      user_id
      staff_id
      outlet_id
      resource_type_id
      resource_type_name
      service_id
      service_name
      booked_date
      from_time
      duration
      number_of_people
      status
      invoice_no
      booking_from
      amount
      special_rate_amount
      special_rate_id
      guest
      user_contact
      user_fullname
      payment_method
      booking_no
      checkin_status
      checkin_at
      checkin_resource_id
      created_at
      updated_at
      deleted_at
      created_by_staff_id
      package_transaction_id
      commissionId
      customCommission
      staff{
        id
        fullname
      }
      user{
        id
        user_contact
        user_fullname
        created_at
        deleted_at
      }
      outlet {
       id
       name
      }
      commission {
        id
        service_id
        commission
        type
      }
  }
}`

export const GET_ALL_SALES = gql`
query getAllSales($id: ID, $sales_type: salesType, $outlet_id: Int, $startDate: String, $endDate: String) {
  getAllSales(id: $id, sales_type: $sales_type, outlet_id: $outlet_id, startDate: $startDate, endDate: $endDate) {
      id
      sales_type
      status
      invoice_no
      package_id
      booking_id
      amount
      user_id
      outlet_id
      staff_id
      online_booking
      created_at
      updated_at
      deleted_at
      voided_at
      User{
        id
        user_contact
        user_fullname
        created_at
        deleted_at
      }
      Outlet{
        id
        name
      }
      payment_methods{
        id
        paymentMethod{
          id
          name
        }
        amount
        salesId
        paymentMethodId
      }
      staff{
        id
        fullname
      }
      split_payment
      tax_amount
  }
}`

export const GET_SALE = gql`
query sales($id: ID!) {
  sales(id: $id) {
      id
      sales_type
      status
      invoice_no
      package_id
      booking_id
      amount
      user_id
      outlet_id
      staff_id
      online_booking
      created_at
      updated_at
      deleted_at
      voided_at
      User{
        id
        user_contact
        user_fullname
        created_at
        deleted_at
      }
      Outlet{
        id
        name
      }
      payment_methods{
        id
        paymentMethod{
          id
          name
        }
        amount
        salesId
        paymentMethodId
      }
      staff{
        id
        fullname
      }
      pdfFile{
        id
        filename
        type
      }
      split_payment
      tax_amount
  }
}`

export const GET_ALL_OUTLET_QUERY = gql`
query Outlets {
  outlets {
    id
    name
  }
}
`

export const GET_STAFF_COMMISSION_BY_BOTH_ID = gql`
  query getStaffCommissionByStaffSerice($staff_id: Int!, $service_id: Int!) {
    getStaffCommissionByStaffSerice(staff_id: $staff_id, service_id: $service_id) {
      commission
      type
      staff_id
      service_id
      id
    }
  }
`;

export const GET_STAFFS_BY_OUTLET_QUERY = gql`
      query staffsByOutlet($outlet_id:Int) {
        staffsByOutlet(outlet_id:$outlet_id) {
          staff{
            fullname
            id
          }
          outlet{
            name
            id
          }
        }
      }
  `

export const GET_COMMISSION_BY_ID = gql`
  query getCommissionListById($id: ID!) {
    getCommissionListById( id: $id) {
        id
        user_id
        staff_id
        outlet_id
        resource_type_id
        resource_type_name
        service_id
        service_name
        booked_date
        from_time
        duration
        number_of_people
        status
        invoice_no
        booking_from
        amount
        special_rate_amount
        special_rate_id
        guest
        user_contact
        user_fullname
        payment_method
        booking_no
        checkin_status
        checkin_at
        checkin_resource_id
        created_at
        updated_at
        deleted_at
        created_by_staff_id
        package_transaction_id
        commissionId
        customCommission
        staff{
          id
          fullname
        }
        user{
          id
          user_contact
          user_fullname
          created_at
          deleted_at
        }
        outlet {
         id
         name
        }
        commission {
          id
          service_id
          commission
          type
        }
    }
  }`

export const GET_ALL_TAX = gql`
  query getAllTax {
    getAllTax {
      id
      name
      amount
      tax_sales {
        id
        sales_id
        tax_id
        amount
      }
    }
  }
`;

export const GET_TAX = gql`
  query getTaxById($id: ID!) {
    getTaxById(id: $id) {
      id
      name
      amount
      tax_sales {
        id
        sales_id
        tax_id
        amount
      }
    }
  }
`;

export const GET_ALL_PACKAGES_QUERY = gql`
query getAllPackages { 
  getAllPackages {
    id
    package_name
    description
    price
    status
    duration_days
    Package_Details{
      id
      service_type_id
      service_id
      resource_type_id
      resource_id
    }
  }
}
`

export const GET_OUTLET_QUERY = gql`
query outletById($id: ID!) { 
  outletById(id: $id) {
    id
    name
    image_id
    address1
    address2
    address3
    city
    state
    postcode
    country
    longitude
    latitude
    contact
    email
    fax
    type
    online_booking
    created_at
    updated_at
    deleted_at
  }}`


export const CREATE_INVOICE_PDF_MUTATION = gql`
mutation CreateInvoicePdf($type: String!, $filename: String!, $salesID: Int!) {
  createInvoicePdf(type: $type, filename: $filename, salesID: $salesID) {
    id
    type
    filename
  }
}
`;

export const CREATE_INVOICE_MUTATION = gql`
  mutation createInvoiceBySalesId($id: Int!) {
    createInvoiceBySalesId(id: $id) {
      id
      invoice_no
      created_at
      updated_at
    }
  }
`;

export const GET_SETTINGS_QUERY = gql`
query settings {
  settings {
    slot_duration,
    booking_url
    invoice_prefix
    image_id
    name
  }
}
`


export const GET_OUTLET_SESSION_QUERY = gql`
    query outletsession($outlet_id:Int, $date:String){
      outletSessions(outlet_id:$outlet_id, date:$date){
        from_time
        to_time
        date
        day
        name
        off_day
        outlet_sessions{
          outlet_id
        }
      }
    }
  `
export const GET_UPCOMING_BOOKINGS = gql`
  query GetUpcomingBookings($outletIds: [Int], $serviceIds: [Int], $staffId: Int!) {
    getUpcomingBookings(outletIds: $outletIds, serviceIds: $serviceIds, staffId: $staffId) {
      id
      service_name
      status
      resource_type_name
      outlet_id
      booked_date
      from_time
      duration
      booking_from
      outlet{
        name
        id
      }
    }
  }
`

export const GET_STAFF_ROSTER_QUERY = gql`
query staffroster($outlet_id:Int, $staff_id:Int) {
  staffroster(outlet_id:$outlet_id, staff_id:$staff_id) {
    id
    staff_id
    outlet_id
  }
}
`

export const DELETE_STAFF_ROSTER_BY_OUTLET_ID = gql`
mutation deleteStaffRosterByOutletId($outlet_id:Int!, $staff_id:Int!) {
  deleteStaffRosterByOutletId(outlet_id:$outlet_id, staff_id:$staff_id) {
    success
  }
}
`

export const DELETE_STAFF_OUTLET = gql`
mutation deleteStaffOutletByOutletId($outlet_id:Int!, $staff_id:Int!) {
  deleteStaffOutletByOutletId(outlet_id:$outlet_id, staff_id:$staff_id) {
    success
  }
}
`

export const CHECK_STAFF_AVAILBILITY = gql`
query checkStaffAvailability($staffId: Int!,$outletId:Int!, $date: String!, $start: String!, $end: String!){
  checkStaffAvailability(staffId:$staffId, outletId:$outletId, date:$date, start:$start, end:$end){
    isAvailable
  }
}
`