<template>
  <v-app>
  <SideMenu submenuPage="Settings">
    <v-alert
      :type="alert_type || 'info'"
      dismissible
      v-model="alert"
    >{{message}}</v-alert>
    <v-card v-if="staffPermission.viewGeneral">
      <v-list-group value="true">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="color:black">General</v-list-item-title>
          </v-list-item-content>
        </template>
        <div class="padding-30">
          <v-list-item>
            <v-row no-gutters>
              <v-col cols="3">
                <v-subheader class="no-padding-left" style="color:black">Website Name: </v-subheader>
              </v-col>
              <v-col >
                <v-text-field
                :readonly="!staffPermission.updateGeneral"
                  v-model="websiteName"
                  class="p-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row no-gutters>
              <v-col cols="3">
                <v-subheader class="no-padding-left" style="color:black">Website Icon: </v-subheader>
              </v-col>
              <v-col cols="6">
                <div id="app">
                  <vue-dropzone
                    ref="dropzone"
                    id="drop1"
                    :options="dropOptions"
                    :destroyDropzone="false"
                    @vdropzone-complete="afterComplete"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-removed-file='removeThisFile'
                  ></vue-dropzone>
                  <input id="image_id" type="hidden" v-model="image_id"/>
                </div>
              </v-col>
            </v-row>
          </v-list-item>
          <!-- <v-list-item>
            <v-row no-gutters>
              <v-col cols="3">
                <v-subheader class="no-padding-left" style="color:black">Currency Format: </v-subheader>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  placeholder="RM"
                  :readonly="!staffPermission.updateGeneral"
                  v-model="settings.currency_format"
                  class="p-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item> -->
          <v-list-item>
            <v-row no-gutters>
              <v-col cols="3">
                <v-subheader class="no-padding-left" style="color:black">Invoice Prefix: </v-subheader>
              </v-col>
              <v-col cols="4">
                <v-text-field
                  placeholder="OASIS"
                  :readonly="!staffPermission.updateGeneral"
                  v-model="invoice_prefix"
                  class="p-0"
                ></v-text-field>
              </v-col>
              <v-col cols="4">
                <v-subheader>e.g. {{ invoice_prefix || "[Prefix]" }}0000001</v-subheader>
              </v-col>
            </v-row>
          </v-list-item>
          <v-list-item>
            <v-row no-gutters>
              <v-col cols="3">
                <v-subheader class="no-padding-left" style="color:black">Online Booking URL</v-subheader>
              </v-col>
              <v-col >
                <v-text-field
                :readonly="!staffPermission.updateGeneral"
                  v-model="settings.booking_url"
                  class="p-0"
                ></v-text-field>
              </v-col>
            </v-row>
          </v-list-item>
        </div>
      </v-list-group>
    </v-card>
    <br/>
    <v-card v-if="staffPermission.viewOnlineBookingFlow">
      <v-list-group value="true">
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="color:black">Booking Flow</v-list-item-title>
          </v-list-item-content>
        </template>
        <div class="padding-30">
        <v-list-item>
          <v-row no-gutters>
            <v-text-field
              v-model="settings.slot_duration"
              :readonly="!staffPermission.updateOnlineBookingFlow"
              label="Slot Duration"
              class="no-padding-left"
            ></v-text-field>
            <v-col cols="3">
              <v-select
                :items="fields"
                v-model="field"
                label="Field"
              ></v-select>
            </v-col>
            <v-col cols="1"></v-col>
            <v-col cols="1" align-self="center">
              <v-btn
                color="primary"
                class="mr-4"
                @click="add"
                :disabled="!staffPermission.updateOnlineBookingFlow"
              >
                + Add
              </v-btn>
            </v-col>
          </v-row>
        </v-list-item>
        <v-list-item>
          <draggable tag="ul" :list="bookingflow" class="list-group" handle=".handle" style="padding-left:0px;width:100%" :disabled="!staffPermission.updateOnlineBookingFlow">
            <li
              class="list-group-item"
              v-for="(element, idx) in bookingflow"
              :key="element.idx"
            >
              <i class="fa fa-align-justify handle"></i>

              <span class="text">{{ element.type }} </span>

              <input class="checkbox" type="checkbox" v-model="element.required" :disabled="!staffPermission.updateOnlineBookingFlow"/>
              <span class="text">Required</span>

              <i class="fa fa-times close" @click="removeAt(element,idx)"></i>
            </li>
          </draggable>
        </v-list-item>
        </div>
      </v-list-group>
    </v-card>
    <br/>
    <v-card v-if="staffPermission.viewPaymentMethods">
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="color:black">Payment Methods</v-list-item-title>
          </v-list-item-content>
        </template>
        <div class="padding-30">
          <v-list-item>
            <v-container fluid>
              <v-row>
                <v-col
                  cols="4"
                  v-for="m of paymentMethods"
                  :key="m.id"
                >
                <div class="checkbox-container">
                  <v-checkbox
                    :disabled="!staffPermission.updatePaymentMethods"
                    v-model="m.isActive"
                    :label="m.name"
                    :value="m.isActive"
                    hide-details
                    class="custom-checkbox"
                  ></v-checkbox>
                  <v-btn icon class="minus-btn" v-if="m.name !== 'cash' && m.name !== 'card'" @click="showConfirmationDialog(m)">
                    <v-icon color="error">mdi-minus-circle</v-icon>
                  </v-btn>
                </div>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-text-field
                  label="New Payment Method"
                  v-model="newPaymentMethod"
                  hide-details
                  >
                  </v-text-field>
                </v-col>
                <v-col>
                  <v-btn
                    color="primary"
                    class="mr-4"
                    @click="addNewPaymentMethod"
                    :disabled="!newPaymentMethod"
                  >
                    + Add
                  </v-btn>
                </v-col>
              </v-row>
            </v-container>
          </v-list-item>

        </div>
      </v-list-group>
    </v-card>
    <br/>
    <!-- <v-card>
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="color:black">Online Booking</v-list-item-title>
          </v-list-item-content>
        </template>
        <div class="padding-30">
        <v-list-item>
          <v-row no-gutters>
            <v-text-field
              v-model="settings.booking_url"
              label="Online Booking URL"
              class="no-padding-left"
            ></v-text-field>
          </v-row>
        </v-list-item>
        </div>
      </v-list-group>
    </v-card> -->
    <v-card v-if="staffPermission.viewTax">
      <v-list-group>
        <template v-slot:activator>
          <v-list-item-content>
            <v-list-item-title style="color:black">Tax Management</v-list-item-title>
          </v-list-item-content>
        </template>
        <div class="padding-30">
          <v-list-item>
            <v-container fluid>
              <template>
                <v-data-table
                  :headers="taxHeaders"
                  :items="tax"
                  hide-default-footer
                  class="elevation-1"
                >
                  <template v-slot:top>
                    <v-toolbar
                      flat
                    >
                      <v-spacer></v-spacer>
                      <v-dialog
                        v-model="dialog"
                        max-width="500px"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn
                            color="primary"
                            dark
                            class="mb-2"
                            v-bind="attrs"
                            v-on="on"
                          >
                            + New Tax
                          </v-btn>
                        </template>
                        <v-card>
                          <v-card-title>
                            <span class="text-h5">Tax</span>
                          </v-card-title>

                          <v-card-text>
                            <v-container>
                              <v-row>
                                <v-col
                                >
                                  <v-text-field
                                    v-model="editedItem.name"
                                    label="Tax name"
                                  ></v-text-field>
                                </v-col>
                                <v-col
                                >
                                  <v-text-field
                                    v-model="editedItem.amount"
                                    label="Amount %"
                                  ></v-text-field>
                                </v-col>
                              </v-row>
                              <small>Only will applied when click update button.</small>
                            </v-container>
                          </v-card-text>

                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn
                              color="blue darken-1"
                              text
                              @click="close"
                            >
                              Cancel
                            </v-btn>
                            <v-btn
                              color="success"
                              @click="save"
                              :disabled="!editedItem.name || editedItem.amount <= 0"
                            >
                              Save
                            </v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                      <v-dialog v-model="dialogDelete" max-width="500px">
                        <v-card>
                          <v-card-title class="text-h5">Are you sure you want to delete {{ editedItem.name }} ?</v-card-title>
                          <v-card-text><small>Only will applied when click update button.</small></v-card-text>
                          <v-card-actions>
                            <v-spacer></v-spacer>
                            <v-btn color="blue darken-1" text @click="closeDelete">Cancel</v-btn>
                            <v-btn color="error" @click="deleteItemConfirm">Delete</v-btn>
                          </v-card-actions>
                        </v-card>
                      </v-dialog>
                    </v-toolbar>
                  </template>
                  <template v-slot:item.actions="{ item }">
                    <v-icon
                      small
                      class="mr-2"
                      @click="editItem(item)"
                    >
                      mdi-pencil
                    </v-icon>
                    <v-icon
                      small
                      @click="deleteItem(item)"
                    >
                      mdi-delete
                    </v-icon>
                  </template>
                </v-data-table>
              </template>
            </v-container>
          </v-list-item>

        </div>
      </v-list-group>
    </v-card>
    <br/>
    <div class="text-right">
      <v-btn
        color="success"
        class="mr-4"
        @click="update"
        v-if="staffPermission.updateGeneral || staffPermission.updateOnlineBookingFlow || staffPermission.updatePaymentMethods || staffPermission.updateTax "
      >
        Update
      </v-btn>
    </div>
  </SideMenu>
  <template>
      <v-row justify="center">
        <v-dialog v-model="showDialog" max-width="500px">
          <v-card>
            <v-card-title>Confirm Deletion for {{ paymentMethodToDelete.name }}</v-card-title>
            <v-card-text>Are you sure you want to delete this payment method?</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="secondary" @click="cancelDeletion">No</v-btn>
              <v-btn color="error" @click="deletePaymentMethod(paymentMethodToDelete)">Yes</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu';
import draggable from "vuedraggable";
import gql from 'graphql-tag';
import vueDropzone from "vue2-dropzone";
import * as queries from '@/graphql/gqlQueries.js';
  const GET_SETTINGS_QUERY = gql`
      query settings {
        settings {
          slot_duration,
          booking_url
          invoice_prefix
          image_id
          name
        }
      }
  `
  const GET_BOOKING_FLOW_QUERY = gql`
      query bookingflow {
        bookingflow {
          type
          required
        }
      }
  `
  const GET_PAYMENT_METHODS = gql`
  query {
    paymentMethods {
      id
      name
      isActive
    }
  }
`
  export default {
    name: 'Settings',

    components:{
      SideMenu,
      draggable,
      vueDropzone
    },

    data() {
      return {
        getAllTax : [],
        settings:[],
        paymentMethods: [],
        newPaymentMethod: "",
        bookingflow:[],
        dragging: false,
        fields: ['Outlet', 'Staff', 'Service', 'Service Type', 'Resource', 'Resource Type', 'Date', 'Time', 'Info','DateTime'],
        field: 'Outlet',
        alert:false,
        alert_type:'',
        message:'',
        invoice_prefix:"OASIS",
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
        showDialog: false,
        paymentMethodToDelete: {},
        dialog: false,
        dialogDelete: false,
        taxHeaders: [
          {
            text: 'Tax Name',
            align: 'start',
            value: 'name',
          },
          { text: 'Amount(%)', value: 'amount' },
          { text: 'Actions', value: 'actions', sortable: false, width: '10%' },
        ],
        tax: [],
        editedIndex: -1,
        editedItem: {
          name: '',
          amount: 0,
        },
        defaultItem: {
          name: '',
          amount: 0,
        },
        dropOptions: {
          url: "https://httpbin.org/post",//dummy url, since 'url' is a required field but we are not using it
          maxFilesize: 2, // MB
          maxFiles: 1,
          addRemoveLinks: true,
          uploadMultiple: false,
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
        },
        image_id: null,
        websiteName: null,
        files:[],
      }
    },
    apollo: {
      settings: {
        query: GET_SETTINGS_QUERY,
        result(){
          console.log("set",this.settings)
          this.invoice_prefix = this.settings.invoice_prefix
          this.websiteName = this.settings.name
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null && this.$refs.dropzone){ 
                var file = { name: result.data.imageById.filename, size:123 }
                this.files.push(file)
                var url = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
                if(this.files.length == 1){
                  this.$refs.dropzone.manuallyAddFile(file, url)
                }
              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
      paymentMethods: {
        query: GET_PAYMENT_METHODS,
        result(){
          console.log(this.paymentMethods,"123")
        }
      },
      // getAllTax: {
      //   query: queries.GET_ALL_TAX,
      //   result(){
      //     this.tax=[]
      //     this.getAllTax.forEach(t=>{
      //       this.tax.push(t)
      //     })
      //     console.log('123',this.tax)
      //   }
      // },
      bookingflow: {
        query: GET_BOOKING_FLOW_QUERY,
        result() {
          for(let i=0; i<this.bookingflow.length; i++){
            var index = this.fields.indexOf(this.bookingflow[i].type)
            if(index !== -1){
              this.fields.splice(index, 1)
            }
            if(this.bookingflow[i].__typename !== undefined){
              delete this.bookingflow[i].__typename
            }
          }
        }
      },
    },
    methods: {
      //tax
      editItem (item) {
        this.editedIndex = this.tax.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialog = true
      },
      deleteItem (item) {
        this.editedIndex = this.tax.indexOf(item)
        this.editedItem = Object.assign({}, item)
        this.dialogDelete = true
      },
      deleteItemConfirm () {
        this.tax.splice(this.editedIndex, 1)
        this.closeDelete()
      },
      close () {
        this.dialog = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      closeDelete () {
        this.dialogDelete = false
        this.$nextTick(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
        })
      },
      save () {
        if (this.editedItem.amount !== null) {
        const formattedAmount = parseFloat(this.editedItem.amount);
        if (!isNaN(formattedAmount)) {
          this.editedItem.amount = formattedAmount.toFixed(2);
        }
      }


        if (this.editedIndex > -1) {
          Object.assign(this.tax[this.editedIndex], this.editedItem)
        } else {
          this.tax.push(this.editedItem)
        }
        this.close()
      },

      ////////////////////////////////////////////

      removeAt(element, idx) {
        if(!this.staffPermission.updateOnlineBookingFlow) return
        this.bookingflow.splice(idx, 1);
        this.fields.push(element.type)
      },
      add: function() {
        this.bookingflow.push({type:this.field, required:false});
        var index = this.fields.indexOf(this.field)
        if(index !== -1){
          this.fields.splice(index, 1)
        }
      },
      async update () {

        const UPDATE_SETTINGS_QUERY = gql`
          mutation updateSettings($slot_duration:Int, $booking_url:String, $invoice_prefix:String, $image_id:Int, $name:String) {
              updateSettings(slot_duration: $slot_duration, booking_url:$booking_url,invoice_prefix:$invoice_prefix,image_id:$image_id, name:$name)  {
                id
                slot_duration
                invoice_prefix
                image_id
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: UPDATE_SETTINGS_QUERY, 
            variables:{
              slot_duration: this.settings.slot_duration*1,
              booking_url: this.settings.booking_url,
              invoice_prefix: this.invoice_prefix,
              image_id: this.image_id?this.image_id * 1:this.settings.image_id * 1,
              name: this.websiteName,
            },
          }
        )
        .catch(error => {
          console.log(error)
        })
        .then(result => {
          if(result && result.data.updateSettings != null){ 
            console.log("Create successfully")
            const DELETE_BOOKING_FLOW_QUERY = gql`
              mutation deleteBookingFlow {
                  deleteBookingFlow{
                    required
                  }
              }
            `
            this.$apollo.mutate(
              { 
                mutation: DELETE_BOOKING_FLOW_QUERY,
              }
            )
            .catch(error => {
              console.log(error)
            })
            .then(() => {

              const CREATE_BOOKING_FLOW_QUERY = gql`
              mutation createBookingFlow($bookingflows:[BookingFlowInput]) {
                  createBookingFlow(bookingflows:$bookingflows)  {
                    required
                  }
                }
              `
              this.$apollo.mutate(
                { 
                  mutation: CREATE_BOOKING_FLOW_QUERY, 
                  variables:{
                    bookingflows:this.bookingflow
                  },
                }
              ).catch(error => {
                console.log(JSON.stringify(error, null, 2));
              })
              .then(() => {
                const UPDATE_PAYMENT_METHODS = gql`
                  mutation updatePaymentMethod($id: ID!, $isActive: Boolean  ) {
                    updatePaymentMethod(id: $id, isActive: $isActive)  {
                        id
                        name
                        isActive
                      }
                    }
                `
                const promises = this.paymentMethods.map((element) => {
                  return this.$apollo.mutate({
                    mutation: UPDATE_PAYMENT_METHODS,
                    variables: {
                      id: element.id,
                      isActive: element.isActive ? true : false,
                    },
                  });
                });

                Promise.all(promises)
                .then(async () => {
                  console.log("123",this.getAllTax,this.tax)
                  if(!(JSON.stringify(this.tax) === JSON.stringify(this.getAllTax))){
                    console.log("runcwx")
                    const DELETE_All_TAX = gql`
                      mutation deleteTax {
                        deleteTax{
                            success
                          }
                      }
                    `
                    if (this.getAllTax.length > 0) {
                      try {
                        const result = await this.$apollo.mutate({
                          mutation: DELETE_All_TAX,
                        });
                        console.log("success", result);
                      } catch (error) {
                        console.log(error);
                      }
                    }
                    const CREATE_TAX = gql`
                      mutation createTax($name: String!, $amount: Float!) {
                        createTax(name: $name, amount: $amount)  {
                            name
                            amount
                          }
                        }
                    `
                    const promises = this.tax.map((element) => {
                      return this.$apollo.mutate({
                        mutation: CREATE_TAX,
                        variables: {
                          name: element.name,
                          amount: element.amount * 1,
                        },
                      });
                    });
                    Promise.all(promises)
                    .then(() => {
                      this.alert = true
                      this.alert_type = 'success'
                      this.message = 'Settings updated successfully'
                      window.scrollTo(0,0)
                    }).catch(error => {
                      this.alert = true
                      this.alert_type = 'error'
                      this.message = 'Settings updated fail'
                      console.log(JSON.stringify(error, null, 2));
                      window.scrollTo(0,0)
                    })
                  } else{
                    this.alert = true
                    this.alert_type = 'success'
                    this.message = 'Settings updated successfully'
                    window.scrollTo(0,0)
                  }
                })
                .catch((error) => {
                  console.log(JSON.stringify(error, null, 2));
                  this.alert = true
                  this.alert_type = 'error'
                  this.message = 'Settings updated fail'
                  window.scrollTo(0,0)
                });
              })
              
            })
            
          } else {
            console.log("Create failed")
          }
        });
      },
      addNewPaymentMethod(){
        const CREATE_PAYMENT_METHOD_MUTATION = gql`
          mutation CreatePaymentMethod($name: String!, $isActive: Boolean!) {
            createPaymentMethod(name: $name, isActive: $isActive) {
              id
              name
              isActive
            }
          }
        `;
        this.$apollo.mutate({
           mutation: CREATE_PAYMENT_METHOD_MUTATION,
           variables: {
            name : this.newPaymentMethod,
            isActive: true,
           }
        }
        ).catch(error=>{
          console.log(JSON.stringify(error, null, 2));
        }).then(()=>{
          this.newPaymentMethod = null
           this.$apollo.queries.paymentMethods.refetch()
        }
        )
      },
      showConfirmationDialog(paymentMethod) {
        this.paymentMethodToDelete = paymentMethod;
        this.showDialog = true;
      },
      deletePaymentMethod(paymentMethod) {
        console.log(paymentMethod)
        const DELETE_PAYMENT_METHOD_MUTATION = gql`
          mutation DeletePaymentMethod($id: ID!) {
            deletePaymentMethod(id: $id) {
              id
            }
          }
        `
        this.$apollo.mutate({
           mutation: DELETE_PAYMENT_METHOD_MUTATION,
           variables: {
            id : paymentMethod.id,
           }
        }
        ).catch(error=>{
          console.log(JSON.stringify(error, null, 2));
          console.error(error)
        }).then(()=>{
          this.showDialog = false
          // window.location.reload()
          this.$apollo.queries.paymentMethods.refetch();
        }
        )
      },
      cancelDeletion() {
        this.showDialog = false;
        this.paymentMethodToDelete = {}
      },
      afterComplete(file) {
        if(!file.manuallyAdded){
          var req = process.env.VUE_APP_SERVER_DOMAIN + 'image/upload?filename=' + file.name
          fetch(req, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'dataURL': file.dataURL
            })
          })
          .then(res => {
            console.log(res)
            if(!res.ok){
              console.log('error')
            }
            else{
              res.json().then(data => {
                this.files.push({'filename':data.filename})
                const CREATE_IMAGE_QUERY = gql`
                  mutation createImage($type: String!, $filename: String!) {
                      createImage(type: $type, filename: $filename)  {
                        id
                        filename
                      }
                  }
                `
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_IMAGE_QUERY, 
                    variables:{
                      type: "website",
                      filename: data.filename
                    },
                  }
                ).then(result => {
                  if(result.data.createImage != null){ 
                    this.image_id = result.data.createImage.id
                    console.log("Create successfully")
                  } else {
                    console.log("Create failed")
                  }
                });
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        }
      },
      uploadSuccess(file) {
        console.log('File Successfully Uploaded with file name: ' + file.name)
      },
      removeThisFile(){
        this.image_id = 0
      },
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
      this.$apollo.query(
        {
          query: queries.GET_ALL_TAX,
        }
      ).then(async result => {
        this.getAllTax = result.data.getAllTax
        this.tax = JSON.parse(JSON.stringify(this.getAllTax));
        console.log('123',this.tax,result.data)
      })
    }
  }
</script>
<style scoped>
.handle {
  float: left;
  padding-top: 8px;
  padding-bottom: 8px;
}
.checkbox {
  vertical-align: -20%;
}
.close {
  float: right;
  padding-top: 8px;
  padding-bottom: 8px;
}
.text {
  margin: 20px;
  vertical-align: -10%;
}
.checkbox-col {
  display: flex;
  align-items: center;
}

.checkbox-container {
  display: flex;
  align-items: baseline;
  gap: 40px; /* Adjust the gap value as needed */
}

.minus-btn {
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.2s;
}

.checkbox-container:hover .minus-btn {
  visibility: visible;
  opacity: 1;
}

</style>