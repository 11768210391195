<template>
  <v-app>
    <SideMenu submenuPage="Sessions" submenuGroup="Outlet Setup">
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab" style="overflow:visible">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="sessionsById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-select
                  v-model="type"
                  :items="types"
                  item-value="name"
                  item-text="name"
                  label="Type"
                  @change="changeType(type)"
                ></v-select>

                <v-select
                  v-if="type=='Day'"
                  v-model="sessionsById.day"
                  :items="dates"
                  item-value="id"
                  item-text="name"
                  label="Day"
                  required
                ></v-select>

                <div v-if="type=='Date'">
                  Date
                </div>
                <datepicker 
                  v-if="type=='Date'"
                  v-model="sessionsById.date" 
                  name="Date"
                  placeholder="Select Date"
                  input-class="datepicker"
                ></datepicker>

                <v-menu
                  v-if="!sessionsById.off_day"
                  ref="from_time_menu"
                  v-model="from_time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="sessionsById.from_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sessionsById.from_time"
                      label="From Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="sessionsById.from_time"
                    :allowed-minutes="allowedStep"
                    class="mt-4"
                    format="24hr"
                    @click:minute="$refs.from_time_menu.save(sessionsById.from_time)"
                  ></v-time-picker>
                </v-menu>

                <v-menu
                  v-if="!sessionsById.off_day"
                  ref="to_time_menu"
                  v-model="to_time_menu"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  :return-value.sync="sessionsById.to_time"
                  transition="scale-transition"
                  offset-y
                  max-width="290px"
                  min-width="290px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="sessionsById.to_time"
                      label="To Time"
                      prepend-icon="mdi-clock-time-four-outline"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-time-picker
                    v-model="sessionsById.to_time"
                    :allowed-minutes="allowedStep"
                    class="mt-4"
                    format="24hr"
                    @click:minute="$refs.to_time_menu.save(sessionsById.to_time)"
                  ></v-time-picker>
                </v-menu>
                <v-alert
                  v-if="!sessionsById.off_day && sessionsById.from_time >= sessionsById.to_time && sessionsById.from_time && sessionsById.to_time"
                  type="error"
                  class="mt-2"
                  dense
                >
                  From Time must be smaller than To Time
                </v-alert>

                <v-checkbox
                  v-model="sessionsById.off_day"
                  label="Off Day"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
            <v-tab-item value="outlet">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="sessionsById.outlets"
                  v-for="outlet in outlets"
                  :key="outlet.id"
                  :label="outlet.name"
                  :value="outlet.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/sessions' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import Datepicker from 'vuejs-datepicker';
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          created_at
        }
      }
  `
  export default {
    name: 'Outlet',

    components:{
      SideMenu,
      Datepicker,
      Form
    },

    data () {
      
      return {
        sessionsById: {
          name: '',
          day:'',
          date: '',
          off_day: false,
          from_time: '',
          to_time: '',
          outlets:[]
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        serviceTypes: [],

        outlets: [],
        tab: null,

        dates: [{id:1,name:"Monday"},{id:2,name:"Tuesday"},{id:3,name:"Wednesday"},{id:4,name:"Thursday"},{id:5,name:"Friday"},{id:6,name:"Saturday"},{id:7,name:"Sunday"}],
        from_time_menu:false,
        to_time_menu:false,
        type:'Day',
        types:[{name:'Day'},{name:'Date'}]
      }
    },
    apollo: {
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
      }
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if (!this.sessionsById.off_day && this.sessionsById.from_time >= this.sessionsById.to_time) {
          return
        }
        if(validate){
          const CREATE_SESSIONS_QUERY = gql`
            mutation createSessions($name: String!, $day:Int, $date:String, $off_day:Boolean, $from_time:String, $to_time:String) {
                createSessions(name:$name, day:$day, date:$date, off_day:$off_day, from_time:$from_time, to_time:$to_time)  {
                  id
                  name
                }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_SESSIONS_QUERY, 
              variables:{
                name: this.sessionsById.name,
                day: this.sessionsById.day*1,
                date: this.sessionsById.date,
                off_day: this.sessionsById.off_day,
                from_time: this.sessionsById.from_time,
                to_time: this.sessionsById.to_time
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.createSessions != null){ 
              console.log("Create successfully")
              const CREATE_OUTLET_SESSIONS_QUERY = gql`
                mutation createOutletSessions($session_id:Int!, $outlet_id:Int!) {
                    createOutletSessions(session_id:$session_id, outlet_id:$outlet_id)  {
                      session_id
                    }
                }
              `
              for(let i = 0; i < this.sessionsById.outlets.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_OUTLET_SESSIONS_QUERY, 
                    variables:{
                      session_id: result.data.createSessions.id*1,
                      outlet_id: this.sessionsById.outlets[i]*1
                    },
                  }
                )
              }
              this.$router.push({name: 'Sessions', params:{alert:true, alert_type:'success', message:result.data.createSessions.name+' created successfully'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
      allowedStep: m => m % 5 === 0,
      changeType(type){
        if(type == 'Day')
          this.sessionsById.date = null
        else
          this.sessionsById.day = null
      }
    }
  }
</script>