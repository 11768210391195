<template>
  <v-app>
    <SideMenu submenuPage="Services" submenuGroup="Service Setup">
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#price">Price</v-tab>
            <v-tab href="#outlet">Outlet</v-tab>
            <v-tab href="#staff">Staff</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-select
                  v-model="serviceById.service_type_id"
                  :items="serviceTypes"
                  item-value="id"
                  item-text="name"
                  label="Service Type"
                  :rules="[() => !!serviceById.service_type_id || 'Service type is required']"
                ></v-select>

                <v-text-field
                  v-model="serviceById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-text-field
                  v-model="serviceById.description"
                  :counter="191"
                  :rules="characterRules"
                  label="Description"
                ></v-text-field>

                <v-text-field
                  v-model="serviceById.duration"
                  label="Duration (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                  :rules="durationRules"
                ></v-text-field>

                <!-- <v-text-field
                  v-model="serviceById.padding_time"
                  label="Padding Time (minutes)"
                  type="number"
                  class="v-text-field-number"
                  min="0"
                  step="1"
                  oninput="validity.valid||(value='');"
                ></v-text-field> -->

                <div>Image Upload</div>
                <div id="app">
                  <vue-dropzone
                    ref="dropzone"
                    id="drop1"
                    :options="dropOptions"
                    :destroyDropzone="false"
                    @vdropzone-complete="afterComplete"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-removed-file='removeThisFile'
                  ></vue-dropzone>
                  <input id="image_id" type="hidden" v-model="serviceById.image_id"/>
                </div>
                <br/>

                <v-color-picker
                  dot-size="25"
                  hide-mode-switch
                  mode="hexa"
                  swatches-max-height="200"
                  label="Color"
                  v-model="serviceById.color"
                ></v-color-picker>           
              </v-form>
            </v-tab-item>
            <v-tab-item value="price">
              <v-form
                ref="form1"
                lazy-validation
              >
                <v-text-field
                  v-model="serviceById.price"
                  label="Default Price"
                  type="number"
                  class="v-text-field-number"
                  step="0.01"
                  min="0"
                  oninput="validity.valid||(value='');"
                ></v-text-field>
                <div>
                  Custom Daily Price
                </div>
                <table class="roster">
                  <tr>
                    <td>
                      Day
                    </td>
                    <td 
                      v-for="date in dates"
                      :key="date"
                    >
                      {{date}}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      Price
                    </td>
                    <td
                      v-for="(price, index) in prices"
                      :key="index"
                    >
                      <v-text-field
                        v-model="price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                  </tr>
                </table>
                <br/>
                <v-btn
                  color="primary"
                  class="mr-4"
                  @click="addCustomPrice"
                >
                  + New Custom Price
                </v-btn>
                <br/>
                <table>
                  <tr
                    v-for="(custom_price, index) in custom_prices"
                    :key="index"
                  >
                    <td>
                      <v-menu
                        v-model="custom_price.datemenu"
                        :close-on-content-click="false"
                        :nudge-right="40"
                        transition="scale-transition"
                        offset-y
                        min-width="auto"
                      >
                        <template v-slot:activator="{ on, attrs }">
                          <v-text-field
                            v-model="custom_price.date"
                            prepend-icon="mdi-calendar"
                            placeholder="Date"
                            readonly
                            v-bind="attrs"
                            v-on="on"
                          ></v-text-field>
                        </template>
                        <v-date-picker
                          v-model="custom_price.date"
                          @input="custom_price.datemenu = false"
                        ></v-date-picker>
                      </v-menu>
                    </td>
                    <td>
                      <v-text-field
                        v-model="custom_price.amount"
                        label="Price"
                        type="number"
                        step="0.01"
                        min="0"
                      ></v-text-field>
                    </td>
                    <td>
                      <v-btn
                        color="error"
                        @click="removeCustomPrice(index)"
                      >
                        remove
                      </v-btn>
                    </td>
                  </tr>
                </table>
              </v-form>
            </v-tab-item>
            <v-tab-item value="outlet">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="serviceById.outlets"
                  v-for="outlet in outlets"
                  :key="outlet.id"
                  :label="outlet.name"
                  :value="outlet.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
            <v-tab-item value="staff">
              <v-form
                ref="form3"
                lazy-validation
              >
                <v-checkbox
                  v-model="serviceById.staffs"
                  v-for="staff in staffs"
                  :key="staff.id"
                  :label="staff.fullname"
                  :value="staff.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/services' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
          >
            Create
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import vueDropzone from "vue2-dropzone"
  const GET_ALL_SERVICE_TYPES_QUERY = gql`
      query ServiceTypes {
        serviceTypes {
          id
          name
        }
      }
  `
  const GET_ALL_OUTLETS_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
          created_at
        }
      }
  `
  const GET_ALL_STAFFS_QUERY = gql`
      query Staffs {
        staffs {
          id
          fullname
        }
      }
  `
  export default {
    name: 'Service',

    components:{
      SideMenu,
      vueDropzone,
      Form
    },

    data () {
      
      return {
        serviceById: {
          name: '',
          description: '',
          price: '',
          duration: '',
          color: '',
          price_type: 'single',
          service_type_id: '',
          padding_time: '',
          image_id: '',
          staffs:[],
          outlets:[]
        },
        showPassword: false,
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        durationRules: [
          v => v != 0 || 'Duration has to be at least 1 minute',
        ],
        dropOptions: {
          url: "https://httpbin.org/post",//dummy url, since 'url' is a required field but we are not using it
          maxFilesize: 2, // MB
          maxFiles: 1,
          // chunking: true,
          // chunkSize: 500, // Bytes
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
          addRemoveLinks: true
        },
        serviceTypes: [],

        outlets: [],
        staffs: [],
        tab: null,

        dates: {1:"Monday",2:"Tuesday",3:"Wednesday",4:"Thursday",5:"Friday",6:"Saturday",7:"Sunday"},
        prices: [{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null},{amount:null}],
        custom_prices: [{datemenu:false, date:null, amount:null}]
      }
    },
    apollo: {
      serviceTypes: {
        query: GET_ALL_SERVICE_TYPES_QUERY,
        result(){
          if(this.serviceTypes){
            this.serviceById.service_type_id = this.serviceTypes[0].id
          }
        }
      },
      outlets: {
        query: GET_ALL_OUTLETS_QUERY,
      },
      staffs: {
        query: GET_ALL_STAFFS_QUERY,
      }
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const CREATE_SERVICE_QUERY = gql`
            mutation createService($name: String!, $description:String, $price:Float, $duration: Int, $color:String, $price_type: String!, $service_type_id: Int!, $padding_time: Int, $image_id: Int) {
                createService(name:$name, description:$description, price:$price, duration: $duration, color:$color, price_type: $price_type, service_type_id: $service_type_id, padding_time: $padding_time, image_id: $image_id)  {
                  id
                  name
                }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: CREATE_SERVICE_QUERY, 
              variables:{
                name: this.serviceById.name,
                description: this.serviceById.description,
                price: this.serviceById.price*1,
                price_type: 'single',
                duration: this.serviceById.duration*1,
                color: this.serviceById.color,
                service_type_id: this.serviceById.service_type_id*1,
                padding_time: this.serviceById.padding_time*1,
                image_id: this.serviceById.image_id*1
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            console.log(this.prices, this.custom_prices)
            if(result && result.data.createService != null){ 
              console.log("Create successfully")
              const CREATE_SERVICE_OUTLET_QUERY = gql`
                mutation createServiceOutlet($service_id:Int!, $outlet_id:Int!) {
                    createServiceOutlet(service_id:$service_id, outlet_id:$outlet_id)  {
                      service_id
                    }
                }
              `
              const CREATE_STAFF_SERVICE_QUERY = gql`
                mutation createStaffService($staff_id:Int!, $service_id:Int!) {
                    createStaffService(staff_id:$staff_id, service_id:$service_id)  {
                      staff_id
                    }
                }
              `
              const CREATE_CUSTOM_PRICE_QUERY = gql`
                mutation createCustomPrice($resource_type_id:Int, $service_id:Int, $day:Int, $date:String, $amount:Float, $description:String){
                  createCustomPrice(resource_type_id:$resource_type_id, service_id:$service_id, day:$day, date:$date, amount:$amount, description:$description){
                    id
                  }
                }
              `
              for(let i = 0; i < this.prices.length; i++){
                if(this.prices[i].amount != null){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_CUSTOM_PRICE_QUERY, 
                      variables:{
                        service_id: result.data.createService.id*1,
                        day: i+1,
                        amount: this.prices[i].amount*1
                      },
                    }
                  )
                }
              }
              for(let i = 0; i < this.custom_prices.length; i++){
                if(this.custom_prices[i].amount != null && this.custom_prices[i].date != null){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_CUSTOM_PRICE_QUERY, 
                      variables:{
                        service_id: result.data.createService.id*1,
                        date: this.custom_prices[i].date,
                        amount: this.custom_prices[i].amount*1
                      },
                    }
                  )
                }
              }
              for(let i = 0; i < this.serviceById.outlets.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_SERVICE_OUTLET_QUERY, 
                    variables:{
                      service_id: result.data.createService.id*1,
                      outlet_id: this.serviceById.outlets[i]*1
                    },
                  }
                )
              }
              for(let i = 0; i < this.serviceById.staffs.length; i++){
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_STAFF_SERVICE_QUERY, 
                    variables:{
                      staff_id: this.serviceById.staffs[i]*1,
                      service_id: result.data.createService.id*1
                    },
                  }
                )
              }
              this.$router.push({name: 'Service', params:{alert:true, alert_type:'success', message:result.data.createService.name+' created successfully'}})
            } else {
              console.log("Create failed")
            }
          });
        }
      },
      afterComplete(file) {
        if(!file.manuallyAdded){
          var req = process.env.VUE_APP_SERVER_DOMAIN + 'image/upload?filename=' + file.name
          fetch(req, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'dataURL': file.dataURL
            })
          })
          .then(res => {
            if(!res.ok){
              console.log('error')
            }
            else{
              res.json().then(data => {
                const CREATE_IMAGE_QUERY = gql`
                  mutation createImage($type: String!, $filename: String!) {
                      createImage(type: $type, filename: $filename)  {
                        id
                        filename
                      }
                  }
                `
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_IMAGE_QUERY, 
                    variables:{
                      type: "outlet",
                      filename: data.filename,
                    },
                  }
                ).then(result => {
                  if(result.data.createImage != null){ 
                    this.serviceById.image_id = result.data.createImage.id
                    console.log("Create successfully")
                  } else {
                    console.log("Create failed")
                  }
                });
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        }
      },
      uploadSuccess(file, response) {
        console.log('File Successfully Uploaded with file name: ' + response.file + file)
        this.fileName = response.file
      },
      removeThisFile(){
        this.serviceById.image_id = 0
      },
      addCustomPrice() {
        this.custom_prices.push({datemenu:false, date:null, amount:null})
      },
      removeCustomPrice(index) {
        this.custom_prices.splice(index,1)
      }
    }
  }
</script>