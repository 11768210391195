export function generatePermissionArray(permissions, userPermissionList) {
    if (!userPermissionList) {
      return;
    }
  
    const permissionArray = {};
  
    if (userPermissionList.length === 0) {
      for (const permission of permissions) {
        permissionArray[permission.name] = false;
      }
    } else {
      for (const permission of permissions) {
        permissionArray[permission.name] = userPermissionList.includes(permission.name);
      }
    }
  
    return permissionArray;
  }

  export function  formatInvoiceDate(dateString){
    const date = new Date(dateString * 1);
          const day = date.getDate().toString().padStart(2, '0');
          const month = date.toLocaleString('en-US', { month: 'short' });
          const year = date.getFullYear()
          return `${day} ${month} ${year}`;
  }

  export function multiPropertySort(array, sortProperties) {
    return array.sort((a, b) => {
      for (const { property, direction } of sortProperties) {
        var aValue = property.split('.').reduce((obj, key) => (obj ? obj[key] * 1 : undefined), a);
        var bValue = property.split('.').reduce((obj, key) => (obj ? obj[key] * 1: undefined), b);
        if (property === 'created_at') {
          aValue = new Date(aValue).getTime();
          bValue = new Date(bValue).getTime();
        }else if(property === 'id') {
          aValue = aValue*1;
          bValue = bValue*1;
        }
        
  
        if (aValue < bValue) return direction === 'asc' ? -1 : 1;
        if (aValue > bValue) return direction === 'asc' ? 1 : -1;
      }
      return 0;
    });
  }
  

  export default {
    install(Vue) {
      Vue.prototype.$generalFunctions = {
        generatePermissionArray,
        formatInvoiceDate,
        multiPropertySort
      };
    }
  }