<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Calendar">
      <Form>
        <template v-slot:tabs>

        </template>
        <template v-slot:form>
          <v-form
            ref="form"
            lazy-validation
          >
            <v-text-field
              v-model="bookingById.outlet.name"
              label="Outlet"
              readonly
            ></v-text-field>

            <v-select
              v-model="bookingById.staff_id"
              :items="staffs"
              item-value="id"
              item-text="fullname"
              label="Staff"
              required
              no-data-text="No staff available"
            ></v-select>

            <v-text-field
              v-if="bookingById.service_name"
              v-model="bookingById.service_name"
              label="Service"
              readonly
            ></v-text-field>

            <v-text-field
              v-if="bookingById.resource_type_name"
              v-model="bookingById.resource_type_name"
              label="Resource Type"
              readonly
            ></v-text-field>
            
            <div>
              Date
            </div>
            <datepicker 
              v-model="bookingById.booked_date" 
              name="Date"
              placeholder="Select Date"
              @change="changeDate()"
            ></datepicker>

            <v-select
              v-model="bookingById.from_time"
              :items="timeslots"
              item-value="time"
              item-text="time"
              label="Time"
              :rules="timeRules"
              return-object
              required
            ></v-select>

            <v-text-field
              v-model="bookingById.duration"
              label="Duration"
              type="number"
              class="v-text-field-number"
            ></v-text-field>

            <v-text-field
              v-model="bookingById.user_fullname"
              label="Fullname"
              type="text"
              readonly
            ></v-text-field>

            <v-text-field
              v-model="bookingById.user_contact"
              label="Phone number"
              type="text"
              readonly
            ></v-text-field>
          </v-form>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/calendar' })"
          >
            Back to list
          </v-btn>
          <v-btn
            color="error"
            class="mr-4"
            @click.stop="dialog = true"
            :disabled="!staffPermission.updateBooking"
          >
            Cancel Booking
          </v-btn>
          <v-btn
            color="success"
            class="mr-4"
            @click.stop="rearrangeDialog = true"
            :disabled="!staffPermission.updateBooking"
          >
            Rearrange
          </v-btn>
        </template>
      </Form>
    </SideMenu>
    <template>
      <v-row justify="center">
      <v-dialog
        v-model="dialog"
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">
            Cancel Booking
          </v-card-title>

          <v-card-text>
            Are you sure to cancel the booking?
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="dialog = false"
            >
              No
            </v-btn>

            <v-btn
              text
              @click="cancelBooking"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
    <template>
      <v-row justify="center">
      <v-dialog
        v-model="rearrangeDialog"
        max-width="350"
      >
        <v-card>
          <v-card-title class="text-h5">
            Rearrange Booking
          </v-card-title>

          <v-card-text>
            Are you sure to rearrange the booking?<br>
            <small>This action will send an notification to customer.</small>
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              text
              @click="rearrangeDialog = false"
            >
              No
            </v-btn>

            <v-btn
              text
              @click="reschedule()"
            >
              Yes
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
      </v-row>
    </template>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import * as queries from '@/graphql/gqlQueries.js';
import Datepicker from 'vuejs-datepicker';
  const GET_BOOKING_QUERY = gql`
      query Booking($id: ID!) {
        bookingById(id: $id) {
          id
          staff_id
          outlet{
            name
          }
          resource_type_id
          resource_type_name
          service_id
          service_name
          booked_date
          from_time
          duration
          number_of_people
          status
          user_contact
          user_fullname
        }
      }
  `
  const GET_SETTINGS_QUERY = gql`
      query settings {
        settings {
          slot_duration
        }
      }
  `
  const GET_ALL_BOOKINGS_QUERY = gql`
    query Bookings($service_id:Int, $resource_type_id:Int, $booked_date:String, $status:BookingStatus, $outlet_id:Int, $staff_id:Int){
      bookings(service_id:$service_id, resource_type_id:$resource_type_id, booked_date:$booked_date, status:$status, outlet_id:$outlet_id, staff_id:$staff_id){
        id
        service_id
        resource_type_id
        booked_date
        status
        outlet_id
        staff_id
        from_time
        duration
        user_fullname
        user_contact
      }
    }
  `
  const GET_ALL_STAFF_QUERY = gql`
      query staffs($outlet_id:Int, $date:String, $time:String, $service_id:Int) {
        staffs(outlet_id:$outlet_id, date:$date, time:$time, service_id:$service_id) {
          id
          fullname
          staff_outlet{
            outlet_id
          }
          staff_service{
            service_id
          }
          staff_roster{
            date
            day
            from_time
            to_time
            break_from_time
            break_to_time
            off_day
          }
        }
      }
  `
  export default {
    name: 'Booking',

    components:{
      SideMenu,
      Datepicker,
      Form
    },

    data () {
      
      return {
        bookingById: {
          staff:{
            fullname:''
          },
          outlet:{
            name:''
          },
          resource_type_id: '',
          resource_type_name: '',
          service_id: '',
          service_name: '',
          booked_date: '',
          from_time: '',
          duration: '',
          number_of_people: '',
          status: ''
        },
        timeslots:[],
        from_time:"09:00",
        to_time:"22:00",
        timeRules: [
          v => !!v || 'Time is required',
        ],
        settings:[],
        bookings: [],
        menu2:false,
        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,
        booked_date: null,
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
        dialog:false,
        rearrangeDialog: false,
      }
    },
    apollo: {
      // getAllPermissions:{
      //   query: queries.GET_ALL_PERMISSIONS_QUERY,
      // },
      // getStaffRoles:{
      //   query: queries.GET_STAFF_ROLES,
      //   fetchPolicy: 'network-only',
      //   variables() {
      //     return{
      //       staffId: this.$session.get("user_id") * 1
      //     }
      //   },
      //   result() {
      //     this.allPermissionsName = []
      //     this.getStaffRoles.forEach( role =>{
      //       role.role.RolePermission.forEach((item) => {
      //         const name = item.permission.name;
      //         this.allPermissionsName.push(name);
      //       });
      //     })
      //       this.staffPermission = this.$generalFunctions.generatePermissionArray(this.getAllPermissions,this.allPermissionsName)
      //   }
      // },
      settings: {
        query: GET_SETTINGS_QUERY,
        result (){
          this.timeSlot()
        }
      },
      bookingById: {
        query: GET_BOOKING_QUERY,
        fetchPolicy: 'network-only',
        variables() {
          return{
            id: this.routeParam
          }
        },
        result() {
          this.bookingById.booked_date = new Date(this.bookingById.booked_date*1)
          this.booked_date = this.bookingById.booked_date.getTime()
          if(this.bookingById.from_time && !this.bookingById.from_time.includes(':')){
            this.bookingById.from_time = ("0" + (new Date(1620691200000+this.bookingById.from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.bookingById.from_time*1).getMinutes())).slice(-2)
          }
          this.processedTimeSlot()
        }
      },
      bookings: {
        variables() {
          var resource_type_id = null
          if(this.bookingById.resource_type_id){
            resource_type_id = this.bookingById.resource_type_id.id
          }
          return {
            status: "booked",
            booked_date: new Date(this.booked_date).toISOString().substring(0, 10),
            service_id: 0,
            resource_type_id: resource_type_id*1,
            outlet_id: 0,
            staff_id: this.bookingById.staff_id*1
          }
        },
        query: GET_ALL_BOOKINGS_QUERY,
        result() {
          this.bookingById.staff_id = this.bookingById.staff_id.toString()
          if(!this.bookingById.booked_date && !this.bookingById.service_id.id && !this.bookingById.outlet_id && !this.bookingById.staff_id){
            this.bookings = []
          }
          this.processedTimeSlot()
        }
      },
      staffs: {
        query: GET_ALL_STAFF_QUERY,
        variables (){
          return {
            outlet_id: this.bookingById.outlet_id*1,
            service_id: this.service_id,
            date: new Date(this.booked_date).toISOString().substring(0, 10),
            time: this.bookingById.from_time
          }
        },
        result(){
          for(let i=0; i<this.staffs.length; i++){
            if(this.staffs[i].id == 1){
              this.staffs.splice(i, 1)
              break
            }
          }
        }
      },
    },
    methods: {
      changeDate () {
        this.booked_date = this.bookingById.booked_date
      },
      reschedule () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_BOOKING_QUERY = gql`
            mutation updateBooking($id: ID!, $staff_id: Int, $outlet_id:Int, $booked_date:String, $from_time:String, $duration:Int){
              updateBooking(id:$id, staff_id:$staff_id, outlet_id:$outlet_id, booked_date:$booked_date, from_time:$from_time, duration:$duration){
                booking_no
                booked_date
                duration
                service_name
                resource_type_name
                duration
              }
            }
          `
          this.$apollo.mutate(
            { 
              mutation: UPDATE_BOOKING_QUERY, 
              variables:{
                id: this.bookingById.id*1,
                staff_id:this.bookingById.staff_id*1,
                outlet_id:this.bookingById.outlet_id*1, 
                booked_date:this.bookingById.booked_date, 
                from_time:this.bookingById.from_time, 
                duration:this.bookingById.duration*1
              },
            }
          )
          .catch(error => {
            console.log(error)
          })
          .then(result => {
            if(result && result.data.updateBooking != null){ 
              this.$router.push({name: 'Calendar', params:{alert:true, alert_type:'success', message:'Appointment rescheduled successfully'}})
            } else {
              console.log("Reschedule failed")
            }
          });
        }
      },
      cancelBooking() {
        this.dialog = false
        const CANCEL_BOOKING_QUERY = gql`
          mutation cancelBooking($id:ID!) {
              cancelBooking(id:$id)  {
                booking_no
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: CANCEL_BOOKING_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.cancelBooking != null){ 
            console.log("Cancel successfully")
            this.$router.push({name: 'Calendar', params:{alert:true, alert_type:'success', message:result.data.cancelBooking.booking_no+' cancelled successfully'}})
          } else {
            console.log("Cancel failed")
          }
        });
      },
      addMinutes(time, minsToAdd) {
        function D(J){ return (J<10? '0':'') + J;}
        var piece = time.split(':')
        var mins = piece[0]*60 + +piece[1] + +minsToAdd

        return D(mins%(24*60)/60 | 0) + ':' + D(mins%60)
      },
      timeSlot(){
        this.timeslots = []
        var start_time = this.from_time
        var end_time = this.to_time
        var slot_duration = this.settings.slot_duration
        while(start_time!=end_time){
          this.timeslots.push(start_time)
          start_time = this.addMinutes(start_time, slot_duration.toString())
        }
      },
      processedTimeSlot(){
        this.timeSlot()
        for(let i=0; i<this.bookings.length; i++){
          if(this.bookingById.staff_id || this.bookingById.resource_type_id){
            for(let x=0; x<this.timeslots.length; x++){
              if(this.bookings[i].id != this.bookingById.id && ("0" + (new Date(1620691200000+this.bookings[i].from_time*1).getUTCHours())).slice(-2) + ':' + ("0" + (new Date(1620691200000+this.bookings[i].from_time*1).getMinutes())).slice(-2) == this.timeslots[x] && (this.bookingById.service_id || this.bookingById.outlet_id || this.bookingById.staff_id || this.bookingById.resource_type_id)){
                this.timeslots.splice(x,1)
                break
              }
            }
          }
        }
        if(!this.timeslots.includes(this.bookingById.from_time)){
          this.bookingById.from_time = ''
        }
      },
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
