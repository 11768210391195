<template>
  <v-app>
    <SideMenu :alert="alert" :alert_type="alert_type" :message="message" submenuPage="Outlet" submenuGroup="Outlet Setup">
      <v-alert
        :type="alert_type || 'info'"
        dismissible
        v-model="alert"
      >{{message}}</v-alert>
      <Form>
        <template v-slot:tabs>
          <v-tabs
            v-model="tab"
          >
            <v-tabs-slider></v-tabs-slider>
            <v-tab href="#details">Details</v-tab>
            <v-tab href="#sessions">Sessions</v-tab>
          </v-tabs>
        </template>
        <template v-slot:form>
          <v-tabs-items v-model="tab">
            <v-tab-item value="details">
              <v-form
                ref="form"
                lazy-validation
              >
                <v-text-field
                  v-model="outletById.name"
                  :counter="191"
                  :rules="nameRules.concat(characterRules)"
                  label="Name"
                  required
                ></v-text-field>

                <v-container class="padding-0" style="margin:0px">
                  <v-row no-gutters>
                    <v-col>
                      <v-text-field
                        v-model="outletById.address1"
                        label="Address line 1"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.address2"
                        label="Address line 2"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.address3"
                        label="Address line 3"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.city"
                        label="City"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.state"
                        label="State"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.postcode"
                        label="Postcode"
                      ></v-text-field>

                      <v-text-field
                        v-model="outletById.country"
                        label="Country"
                      ></v-text-field>
                    </v-col>

                    <v-col>
                      <v-container>
                        <v-row no-gutters>
                          <v-col>
                            <GmapMap
                              :center="position"
                              :zoom="15"
                              map-type-id="roadmap"
                              class="google-map"
                              @click="relocate"
                            >
                              <GmapMarker
                                :position="position"
                              />
                            </GmapMap>
                          </v-col>
                        </v-row>
                      
                        <v-row>
                          <v-col>
                            <v-text-field
                              v-model="outletById.longitude"
                              label="Longitude"
                              class="md-layout-item"
                            ></v-text-field>
                          </v-col>
                          <v-col>
                            <v-text-field
                              v-model="outletById.latitude"
                              label="Latitude"
                              class="md-layout-item"
                            ></v-text-field>
                          </v-col>
                        </v-row>
                        <v-row no-gutters>
                          <v-col>
                            <v-btn
                              color="info"
                              class="mr-4"
                              @click="centerFromCoordinates"
                            >
                              Center from coordinates
                            </v-btn>
                            <v-btn
                              color="info"
                              class="mr-4"
                              @click='setFromAddress'
                            >
                              Set from address
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>
                    </v-col>
                  </v-row>
                </v-container>

                <v-text-field
                  v-model="outletById.contact"
                  label="Contact"
                ></v-text-field>

                <v-text-field
                  v-model="outletById.email"
                  :counter="191"
                  :rules="emailRules"
                  label="Email"
                ></v-text-field>

                <v-text-field
                  v-model="outletById.fax"
                  label="Fax"
                ></v-text-field>

                <v-checkbox
                  v-model="outletById.online_booking"
                  label="Online booking"
                ></v-checkbox>

                <div>Image Upload</div>
                <div id="app">
                  <vue-dropzone
                    ref="dropzone"
                    id="drop1"
                    :options="dropOptions"
                    :destroyDropzone="false"
                    @vdropzone-complete="afterComplete"
                    @vdropzone-success="uploadSuccess"
                    @vdropzone-removed-file='removeThisFile'
                  ></vue-dropzone>
                  <input id="image_id" type="hidden" v-model="outletById.image_id"/>
                </div>
                <br/>
              </v-form>
            </v-tab-item>
            <v-tab-item value="sessions">
              <v-form
                ref="form2"
                lazy-validation
              >
                <v-checkbox
                  v-model="session_outlets"
                  v-for="session in sessions"
                  :key="session.id"
                  :label="session.name"
                  :value="session.id"
                ></v-checkbox>
              </v-form>
            </v-tab-item>
          </v-tabs-items>
        </template>
        <template v-slot:buttons>
          <v-btn
            class="mr-4"
            text
            @click="$router.push({ path: '/outlets' })"
          >
            Back to list
          </v-btn>
          <v-dialog
              max-width="500"
              v-if="staffPermission.deleteOutlet"
          >
              <template v-slot:activator="{ on, attrs }">
              <v-btn
                  color="error"
                  v-bind="attrs"
                  v-on="on"
                  class="mr-4" 
              >Delete</v-btn>
              </template>
              <template v-slot:default="dialog">
              <v-card>
                  <v-card-title>
                  Are you sure to delete {{ outletById.name }}?
                  </v-card-title>
                  <v-card-actions class="justify-end">
                  <v-btn
                      text
                      @click="dialog.value = false"
                  >Close</v-btn>
                  <v-btn color="error" @click="deleteOutlet(); dialog.value = false">
                      Delete
                  </v-btn>
                  </v-card-actions>
              </v-card>
              </template>
          </v-dialog>
          <v-btn
            color="success"
            class="mr-4"
            @click="validate"
            v-if="staffPermission.updateOutlet"
          >
            Update
          </v-btn>
        </template>
      </Form>
    </SideMenu>
  </v-app>
</template>

<script>
import SideMenu from '/src/components/SideMenu'
import Form from '/src/components/Form'
import gql from 'graphql-tag'
import vueDropzone from "vue2-dropzone"
import * as queries from '@/graphql/gqlQueries.js';
  const GET_OUTLET_QUERY = gql`
      query Outlet($id: ID!) {
        outletById(id: $id) {
          name
          image_id
          address1
          address2
          address3
          city
          state
          postcode
          country
          longitude
          latitude
          contact
          email
          fax
          type
          online_booking,
          outlet_sessions{
            session_id
          }
        }
      }
  `
  const GET_ALL_SESSIONS_QUERY = gql`
    query Sessions {
      sessions {
        id
        name
      }
    }
  `
  export default {
    name: 'Outlet',

    components:{
      SideMenu,
      vueDropzone,
      Form
    },

    data () {
      
      return {
        outletById: {
          name: '',
          image_id: '',
          address1: '',
          address2: '',
          address3: '',
          city: '',
          state: '',
          postcode: '',
          country: '',
          longitude: '',
          latitude: '',
          contact: '',
          email: '',
          fax: '',
          type: '',
          online_booking: true
        },
        
        nameRules: [
          v => !!v || 'Name is required',
        ],
        characterRules: [
          v => (v.length <= 191) || 'Field must be less than or equal to 191 characters',
        ],
        emailRules: [
          v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
        ],
        dropOptions: {
          url: "https://httpbin.org/post",//dummy url, since 'url' is a required field but we are not using it
          maxFilesize: 2, // MB
          maxFiles: 1,
          // chunking: true,
          // chunkSize: 500, // Bytes
          thumbnailWidth: 150, // px
          thumbnailHeight: 150,
          addRemoveLinks: true
        },
        routeParam: this.$route.params.id,
        alert: this.$route.params.alert_type?true:false,
        alert_type: this.$route.params.alert_type,
        message: this.$route.params.message,

        tab:'',
        sessions:[],
        session_outlets:[],

        position:{lat:3.1569486,lng:101.712303},

        files:[],
        getAllPermissions: [],
        allPermissionsName: [],
        staffPermission: {},
      }
    },
    apollo: {
      outletById: {
        query: GET_OUTLET_QUERY,
        pollInterval: 300,
        variables() {
          return{
            id: this.routeParam
          }
        },
        result () {
          if(this.outletById.image_id != 0 && this.outletById.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.outletById.image_id
                },
              }
            ).then(result => {
              if(result.data.imageById != null && this.$refs.dropzone){ 
                var file = { name: result.data.imageById.filename, size:123 }
                this.files.push(file)
                var url = "https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.imageById.filename
                if(this.files.length == 1){
                  this.$refs.dropzone.manuallyAddFile(file, url)
                }
              } else {
                console.log("Image not found")
              }
            });
          }
          var outlets = []
          for(let i=0; i<this.outletById.outlet_sessions.length; i++){
            outlets.push(this.outletById.outlet_sessions[i].session_id.toString())
          }
          this.session_outlets = outlets

          if(this.outletById.latitude){
            this.position.lat = this.outletById.latitude
          }
          if(this.outletById.longitude){
            this.position.lng = this.outletById.longitude
          }
        },
      },
      sessions: {
        query: GET_ALL_SESSIONS_QUERY,
      },
    },
    methods: {
      validate () {
        var validate = this.$refs.form.validate()
        if(!validate){
          window.scrollTo(0,0);
        }
        if(validate){
          const UPDATE_OUTLET_QUERY = gql`
            mutation updateOutlet($id: ID!, $name: String!, $image_id: Int, $address1: String, $address2: String, $address3: String, $city: String, $state: String, $postcode: String, $country: String, $longitude: Float, $latitude: Float, $contact: String, $email: String, $fax: String, $type: String, $online_booking: Boolean) {
                updateOutlet(id:$id, name: $name, image_id: $image_id, address1: $address1, address2: $address2, address3: $address3, city: $city, state: $state, postcode: $postcode, country: $country, longitude: $longitude, latitude: $latitude, contact: $contact, email: $email, fax: $fax, type: $type, online_booking: $online_booking)  {
                  id
                  name
                }
            }
          `

          this.$apollo.mutate(
            { 
              mutation: UPDATE_OUTLET_QUERY, 
              variables:{
                id: this.routeParam,
                name: this.outletById.name,
                image_id: this.outletById.image_id*1,
                address1: this.outletById.address1,
                address2: this.outletById.address2,
                address3: this.outletById.address3,
                city: this.outletById.city,
                state: this.outletById.state,
                postcode: this.outletById.postcode,
                country: this.outletById.country,
                longitude: this.outletById.longitude*1,
                latitude: this.outletById.latitude*1,
                contact: this.outletById.contact,
                email: this.outletById.email,
                fax: this.outletById.fax,
                type: this.outletById.type,
                online_booking: this.outletById.online_booking
              },
            }
          ).then(result => {
            if(result.data.updateOutlet != null){ 
              var outlet_id = result.data.updateOutlet.id*1
              console.log("Update successfully")
              const DELETE_OUTLET_SESSIONS_QUERY = gql`
                mutation deleteOutletSessions($outlet_id:Int!) {
                    deleteOutletSessions(outlet_id:$outlet_id)  {
                      outlet_id
                    }
                }
              `
              const CREATE_OUTLET_SESSIONS_QUERY = gql`
                mutation createOutletSessions($session_id:Int!, $outlet_id:Int!) {
                    createOutletSessions(session_id:$session_id, outlet_id:$outlet_id)  {
                      session_id
                    }
                }
              `
              this.$apollo.mutate(
                { 
                  mutation: DELETE_OUTLET_SESSIONS_QUERY, 
                  variables:{
                    outlet_id: result.data.updateOutlet.id*1
                  },
                }
              )
              .then(()=>{
                for(let i = 0; i < this.session_outlets.length; i++){
                  this.$apollo.mutate(
                    { 
                      mutation: CREATE_OUTLET_SESSIONS_QUERY, 
                      variables:{
                        session_id: this.session_outlets[i]*1,
                        outlet_id:  outlet_id
                      },
                    }
                  )
                }
              })
              this.alert = true
              this.alert_type='success'
              this.message = result.data.updateOutlet.name+' updated successfully'
              window.scrollTo(0,0)
            } else {
              this.alert = true
              this.alert_type='error'
              this.message = 'Updated fail'
              window.scrollTo(0,0)
            }
          });
        }
      },
      deleteOutlet() {
        const DELETE_OUTLET_SESSIONS_QUERY = gql`
          mutation deleteOutletSessions($outlet_id:Int!) {
              deleteOutletSessions(outlet_id:$outlet_id)  {
                deleted_at
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_OUTLET_SESSIONS_QUERY, 
            variables:{
              outlet_id: this.routeParam*1
            },
          }
        )
        const DELETE_OUTLET_QUERY = gql`
          mutation deleteOutlet($id:ID!) {
              deleteOutlet(id:$id)  {
                name
              }
          }
        `
        this.$apollo.mutate(
          { 
            mutation: DELETE_OUTLET_QUERY, 
            variables:{
              id: this.routeParam,
            },
          }
        ).then(result => {
          if(result.data.deleteOutlet != null){ 
            console.log("Delete successfully")
            this.$router.push({name: 'Outlet', params:{alert:true, alert_type:'success', message:result.data.deleteOutlet.name+' deleted successfully'}})
          } else {
            console.log("Delete failed")
          }
        });
      },
      afterComplete(file) {
        if(!file.manuallyAdded){
          var req = process.env.VUE_APP_SERVER_DOMAIN + 'image/upload?filename=' + file.name
          fetch(req, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json'
            },
            body: JSON.stringify({
              'dataURL': file.dataURL
            })
          })
          .then(res => {
            if(!res.ok){
              console.log('error')
            }
            else{
              res.json().then(data => {
                this.files.push({'filename':data.filename})
                const CREATE_IMAGE_QUERY = gql`
                  mutation createImage($type: String!, $filename: String!) {
                      createImage(type: $type, filename: $filename)  {
                        id
                        filename
                      }
                  }
                `
                this.$apollo.mutate(
                  { 
                    mutation: CREATE_IMAGE_QUERY, 
                    variables:{
                      type: "outlet",
                      filename: data.filename
                    },
                  }
                ).then(result => {
                  if(result.data.createImage != null){ 
                    this.outletById.image_id = result.data.createImage.id
                    console.log("Create successfully")
                  } else {
                    console.log("Create failed")
                  }
                });
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
        }
      },
      uploadSuccess(file) {
        console.log('File Successfully Uploaded with file name: ' + file.name)
      },
      removeThisFile(){
        this.outletById.image_id = 0
      },
      centerFromCoordinates(){
        const marker = {
          lat: this.outletById.latitude*1,
          lng: this.outletById.longitude*1
        }
        this.position = marker
      },
      relocate(value){
        this.outletById.latitude = value.latLng.lat()
        this.outletById.longitude = value.latLng.lng()
        const marker = {
          lat: this.outletById.latitude*1,
          lng: this.outletById.longitude*1
        }
        this.position = marker
      },
      async setFromAddress() {
        this.currentPlace = this.outletById.address1
        if(this.outletById.address2){
          this.currentPlace += ','+this.outletById.address2
        }
        if(this.outletById.address3){
          this.currentPlace += ','+this.outletById.address3
        }
        if(this.outletById.postcode){
          this.currentPlace += ','+this.outletById.postcode
        }
        if(this.outletById.city){
          this.currentPlace += ','+this.outletById.city
        }
        if(this.outletById.state){
          this.currentPlace += ','+this.outletById.state
        }
        if(this.outletById.country){
          this.currentPlace += ','+this.outletById.country
        }
        if (this.currentPlace) {
          try {
            const response = await this.$http.get(
              'https://maps.googleapis.com/maps/api/geocode/json?address='+this.currentPlace+'&key=AIzaSyCI1tcmvw0hiVk9bUPOHNjiU9aZq4A4lEE'
            );
            this.position = response.data.results[0].geometry.location
            this.outletById.latitude = this.position.lat
            this.outletById.longitude = this.position.lng
          } catch (error) {
            console.log(error);
          }
        }
      }
    },
    created(){
      this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
          {
            query: queries.GET_STAFF_ROLES,
            variables: {
              staffId: this.$session.get("user_id") * 1
            }
          }
        ).then(async result => {
          this.getStaffRoles = await result.data.getStaffRoles;
          this.allPermissionsName = [];
          this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
              const name = item.permission.name;
              this.allPermissionsName.push(name);
            });
          })
          this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
          this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
      })
    }
  }
</script>
