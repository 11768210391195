<template>
    <v-app>
        <SideMenu submenuPage="PendingPayment">
            <div>
    
                <v-btn  @click="goBack">
                <v-icon left>mdi-arrow-left</v-icon> 
                <span>Back to Calendar</span> 
                </v-btn>
           </div>
            <List>
                <template v-slot:tab-items>
                    <template>
                        <v-data-table :headers="headers" :items="getPendingSales" class="elevation-2" item-key="id">
                            <template v-slot:item="{ item }">
                                <tr v-bind:class="{ 'row-red': item.timePast }">
                                    <td> {{ item.User.user_contact }}</td>
                                    <td>{{ item.Outlet.name }}</td>
                                    <td>{{ item.amount | formatPrice }}</td>
                                    <td>{{ item.online_booking? "Online":item.staff_fullname }}</td>
                                    <td>{{ item.created_at }}</td>
                                    <td>
                                        <v-btn color="green" @click="payNow(item)"
                                            :disabled="!staffPermission.updatePendingOrder">
                                            Pay
                                        </v-btn>
                                    </td>
                                    <td>
                                        <v-btn color="red" @click="openVoidDialog(item)"
                                            :disabled="!staffPermission.voidPendingOrder">
                                            Void
                                        </v-btn>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <div class="d-flex align-items-center mt-2">
                        <v-icon color="rgb(250, 80, 80)">mdi-square</v-icon>
                        <span> Past Booking Date</span>
                    </div>
                </template>
            </List>
        </SideMenu>
        <template>
            <v-row justify="center">
                <v-dialog v-model="voidDialog" max-width="500" @click:outside="cancelVoid">
                    <v-card>
                        <v-card-title>
                            Are You Sure Want To Void Booking(s) ?
                        </v-card-title>
                        <v-card-text>
                            Name: {{ selectedSales.User.user_fullname }} <br>Phone Number: {{
                                selectedSales.User.user_contact }}
                            <v-list-item v-for="booking in selectedSales.bookingList" :key="booking.id">
                                <v-list-item-content>
                                    <v-list-item-title v-if="booking.resource_type_name">{{ booking.resource_type_name
                                    }}</v-list-item-title>
                                    <v-list-item-title v-if="booking.service_name">{{ booking.service_name }}({{
                                        booking.staff_fullname }})</v-list-item-title>
                                    <v-list-item-subtitle>{{ booking.booked_date | formatDate }} {{ booking.from_time |
                                        formatTime }}</v-list-item-subtitle>
                                </v-list-item-content>
                            </v-list-item>

                        </v-card-text>
                        <v-card-actions>
                            <v-spacer></v-spacer>

                            <v-btn text @click="cancelVoid">
                                No
                            </v-btn>

                            <v-btn color="red" @click="voidSales(selectedSales)">
                                Void
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
            <v-row justify="center">
                <v-dialog v-model="payDialog" max-width="1000" @click:outside="cancelPay">
                    <v-card class="minh-80v">
                        <v-card-title class="pr-1">
                            <template>
                                <h3 class="m-0">Pay Order</h3>
                                <v-spacer></v-spacer>
                                <v-btn icon @click="cancelPay">
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                            </template>
                        </v-card-title>
                        <v-card-text class="package_bill">
                            <div class="column_one">
                                <div style="max-height:25%">
                                    <v-row>
                                        <v-col cols="6">
                                            <h4 class="m-0">Customer Details</h4>
                                            <p class="m-0">Name: {{ selectedSales.User.user_fullname }}
                                            </p>
                                            <p class="m-0">Phone Number: {{ selectedSales.User.user_contact
                                            }}</p>
                                            <br>
                                        </v-col>
                                        <v-col cols="6">
                                            <h4 class="m-0">Staff Details</h4>
                                            <v-row class="d-flex justify-content-between align-items-center mt-0">
                                                <v-col cols="4" class="p-0">
                                                    <v-subheader>Staff:</v-subheader>
                                                </v-col>
                                                <v-col cols="7" class="text-right py-0">
                                                    {{ this.$session.get("user_fullname") }}
                                                </v-col>
                                            </v-row>
                                            <v-row class="d-flex justify-content-between">
                                                <v-col cols="3" class="p-0">
                                                    <v-subheader>Outlet:</v-subheader>

                                                </v-col>
                                                <v-col cols="7" class="text-right py-0">
                                                    <v-select v-model="currentOutlet" :items="staff_outlet" item-text="name"
                                                        label="Outlet" @input="updateCurrentOutlet" solo></v-select>
                                                </v-col>
                                            </v-row>
                                        </v-col>
                                    </v-row>

                                </div>
                                <h4 class="m-0">Appointment Details</h4>
                                <template>
                                    <div class="scrollY pt-2" style="height:50vh">
                                        <template>
                                            <div>
                                                <v-list-item v-for="booking in selectedSales.bookingList" :key="booking.id">
                                                    <v-list-item-content>
                                                        <v-list-item-title v-if="booking.resource_type_name">{{
                                                            booking.resource_type_name }}</v-list-item-title>
                                                        <v-list-item-title v-if="booking.service_name">{{
                                                            booking.service_name }}({{
        booking.staff_fullname }})</v-list-item-title>
                                                        <v-list-item-subtitle>{{ booking.booked_date | formatDate }} {{
                                                            booking.from_time | formatTime }}</v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                            </div>
                            <div class="column_two">
                                <div class="paymentDetails">
                                    <h4>Payment Details</h4>
                                    <v-row class="d-flex justify-content-between mt-0">
                                        <v-col cols="5" class="p-0">
                                            <v-subheader>
                                                <h2 class="m-0">Total</h2>
                                            </v-subheader>
                                        </v-col>
                                        <v-col cols="7" class="text-right py-0">
                                            <v-text-field v-model="selectedSales.amount" solo reverse disabled
                                                class="disabled-field" suffix="RM" type="number" step="0.01"
                                                min="0"></v-text-field>
                                        </v-col>
                                    </v-row>
                                    <v-row class="d-flex justify-content-between mt-0">
                                        <v-col cols="6" class="p-0">
                                            <v-subheader class="pr-0 py-0">Payment Method</v-subheader>
                                        </v-col>
                                        <v-col cols="6" class="text-right pr-0">
                                            <v-container class="p-0" fluid>
                                                <v-switch v-model="splitPayment" @click="resetMethodPayment"
                                                    label="Split Payment" hide-details
                                                    class="small-switch mt-0 pt-0"></v-switch>
                                            </v-container>
                                        </v-col>
                                        <v-col v-if="splitPayment" class="py-0">
                                            <v-row v-for="m of paymentMethodsList" :key="m.id">
                                                <v-col cols="6" class="p-1">
                                                    <v-subheader> {{ m.name }} </v-subheader></v-col>
                                                <v-col cols="6" class="p-1">
                                                    <v-text-field v-model="m.amount" solo reverse @blur="calcRemainBalance"
                                                        suffix="RM" type="number" step="0.01" min="0"
                                                        hide-details></v-text-field>
                                                </v-col>
                                                <v-col cols="12" v-if="m.name == 'cash'">
                                                    <v-row no-gutters>
                                                        <v-col v-for="amount of [5, 10, 50, 100]" :key="amount"
                                                            class="text-center">
                                                            <v-btn color="neutralgray" @click='addCashAmount(amount)' small>
                                                                RM {{ amount }}</v-btn>
                                                        </v-col>
                                                    </v-row>
                                                </v-col>
                                            </v-row>
                                            <v-row>
                                                <v-col class="p-1">
                                                    <v-subheader> Balance: RM{{ remainBalance | formatPrice }}
                                                    </v-subheader></v-col>
                                            </v-row>
                                        </v-col>
                                        <v-col v-if="!splitPayment" class="py-0">
                                            <v-card class="m-0 p-0">
                                                <v-container fluid class="my-0">
                                                    <v-radio-group v-model="selectedPaymentMethod" class="mt-0 pt-0"
                                                        hide-details>
                                                        <v-radio v-for="n in paymentMethodsList" :key="n.id" :label="n.name"
                                                            :value="n"></v-radio>
                                                    </v-radio-group>
                                                </v-container>
                                            </v-card>
                                        </v-col>
                                    </v-row>
                                </div>
                                <div>
                                    <v-row>
                                        <v-col>
                                            <v-btn color="green" @click="approvedSales"
                                                :disabled="!selectedSales || (remainBalance * 1 != 0 && splitPayment)"
                                                class="p-2 w-100">
                                                PAY
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </div>
                            </div>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </v-row>
        </template>
        <template>
          <v-row justify="center">
            <v-dialog
              v-model="pdfDialog"
              max-width="450"
              @click:outside="closePDFDialog"
            >
            <v-card>
              <v-card-text class="d-flex flex-column justify-content-center align-items-center">
                  <v-icon v-if="pdfUploaded" color="green" style="font-size: 100px;">mdi-check-circle</v-icon>
                  <v-icon v-if="!pdfUploaded" color="error" style="font-size: 100px;">mdi-close-circle</v-icon>
                  <h3>{{ pdfUploaded ? "Purchase Successfully!" : "PDF Build Failed!" }}</h3>

                  <div class="text-right">
                    <v-btn
                      color="primary"
                      class="mr-4"
                      @click="downloadPdf"
                      :disabled = "!pdfUploaded"
                    >
                    Download PDF
                    </v-btn>
                    <v-btn
                      color="primary"
                      class="mr-4"
                      @click="closePDFDialog"
                    >
                    OK
                    </v-btn>
                  </div>
              </v-card-text>
            </v-card>
          </v-dialog>
        </v-row>
      </template>
        <template>
            <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                size="64"
            ></v-progress-circular>
            </v-overlay>
        </template>
    </v-app>
</template>
  
<script>
import SideMenu from "/src/components/SideMenu";
import List from "/src/components/List";
import gql from "graphql-tag";
import axios from 'axios';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import * as queries from '@/graphql/gqlQueries.js';
const GET_PENDING_SALES_QUERY = gql`
  query GetPendingSales {
    getPendingSales {
      id
      sales_type
      status
      invoice_no
      tax_amount
      amount
      user_id
      outlet_id
      staff_id
      online_booking
      created_at
      updated_at
      deleted_at
      booking_id
      voided_at
      User {
        user_fullname
        user_contact
        id
      }
      Outlet {
        id
        name
      }
    }
  }
`;
const GET_ALL_USERS_QUERY = gql`
      query staffs {
        staffs {
          id
          fullname
        }
      }
  `
const GET_BOOKING_QUERY = gql`
    query Booking($id: ID!) {
    bookingById(id: $id) {
        id
        staff_id
        outlet{
        name
        }
        resource_type_name
        service_name
        booked_date
        from_time
        duration
        package_transaction_id
        amount
    }
    }
`
const GET_PAYMENT_METHODS = gql`
  query {
    paymentMethods {
      id
      name
      isActive
    }
  }
`
const GET_STAFF_QUERY = gql`
      query Staff($id: ID!) {
        staffById(id: $id) {
            fullname
            designation
            contact
            email
            created_at
            status
            admin
            supervisor
            staff_outlet{
              outlet_id
            }
        }
      }
  `
const GET_ALL_OUTLET_QUERY = gql`
      query Outlets {
        outlets {
          id
          name
        }
      }
  `
export default {
    name: "PendingPayment",

    components: {
        SideMenu,
        List,
    },
    data() {
        return {
            websiteName: null,
            imageURL: null,
            getPendingSales: [],
            staffs: [],
            currentOutlet: {},
            paymentMethodsList: [],
            staff_outlet: [],
            splitPayment: false,
            remainBalance: 0,
            selectedPaymentMethod: {},
            selectedSales: {
                User: {
                    user_fullname: null
                },
                bookingList: [],
            },
            voidDialog: false,
            payDialog: false,
            pdfDialog: false,
            pdfUploaded: false,
            pdfSalesIid: null,
            headers: [
                {
                    text: 'Customer Contact',
                    value: 'User.user_fullname',
                    class: 'white--text',
                    width: '15%'
                },
                {
                    text: 'Outlet',
                    value: 'Outlet.name',
                    class: 'white--text',
                    width: '15%'
                },
                {
                    text: 'Amount',
                    value: 'amount',
                    class: 'white--text',
                    width: '10%'
                },
                {
                    text: 'Staff',
                    value: 'staff_fullname',
                    class: 'white--text',
                    width: '15%'
                },
                {
                    text: 'Created At',
                    value: 'created_at',
                    class: 'white--text',
                    width: '30%'
                },
                {
                    text: 'Pay',
                    value: '',
                    class: 'white--text',
                    width: '7.5%'
                }
                ,
                {
                    text: 'Void',
                    value: '',
                    class: 'white--text',
                    width: '7.5%'
                }
            ],
            getAllPermissions: [],
            allPermissionsName: [],
            staffPermission: {},
            overlay: false,
        };
    },
    apollo: {
        staffs: {
            query: GET_ALL_USERS_QUERY,
        },
        paymentMethods: {
            query: GET_PAYMENT_METHODS,
            result() {
                const result = [...this.paymentMethods]; // Create a copy of paymentMethods array
                const filteredMethods = result.filter(m => m.isActive);
                const updatedMethodsList = filteredMethods.map(m => ({ ...m, amount: 0 }));

                this.paymentMethodsList = updatedMethodsList;
                if (updatedMethodsList.length > 0) {
                    this.selectedPaymentMethod = updatedMethodsList[0];
                }
                console.log("methodList", this.paymentMethodsList);
            }
        },
        getPendingSales: {
            query: GET_PENDING_SALES_QUERY,
            pollInterval: 300,
            async result() {
                const sortProperties = [
                    { property: 'id', direction: 'desc' },
                    { property: 'created_at', direction: 'desc' },
                ];

                // Use the multiPropertySort function to sort the commissionList
                this.getPendingSales = await this.$generalFunctions.multiPropertySort(this.getPendingSales, sortProperties);
                const staffMap = this.staffs.reduce((map, staff) => {
                    map[staff.id] = staff.fullname;
                    return map;
                }, {});

                const options = {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                    hour: "numeric",
                    minute: "numeric",
                    hour12: true,
                    timeZone: "Asia/Singapore",
                };

                const fetchBookings = sale => {
                    const bookingPromises = sale.booking_id.map(booking => {
                        return this.$apollo.query({
                            query: GET_BOOKING_QUERY,
                            variables: {
                                id: booking * 1,
                            },
                        });
                    });

                    return Promise.all(bookingPromises)
                        .then(results => {
                            const bookingList = results.map(result => {
                                const booking = result.data.bookingById;
                                if (booking.staff_id) {
                                    booking.staff_fullname = staffMap[booking.staff_id];
                                }
                                booking.date = new Date(booking.booked_date * 1);
                                const currentDate = new Date();
                                if (booking.date.getTime() < currentDate.getTime()) {
                                    sale.timePast = true;
                                }
                                return booking;
                            });
                            return bookingList;
                        });
                };

                try {
                    const result = await this.$apollo.query({
                        query: GET_PENDING_SALES_QUERY,
                    });

                    const pendingSales = result.data.getPendingSales;

                    const pendingSalesPromises = pendingSales.map(sale => {
                        sale.staff_fullname = staffMap[sale.staff_id];

                        const createdAt = new Date(sale.created_at * 1);
                        if (!isNaN(createdAt.getTime())) {
                            sale.created_at = new Intl.DateTimeFormat("en-US", options).format(createdAt);
                        }
                        if (sale.deleted_at) {
                            const deletedAT = new Date(sale.deleted_at * 1);
                            if (!isNaN(deletedAT.getTime())) {
                                sale.deleted_at = new Intl.DateTimeFormat("en-US", options).format(deletedAT);
                            }
                        }
                        if (sale.updated_at) {
                            const updatedAt = new Date(sale.updated_at * 1);
                            if (!isNaN(updatedAt.getTime())) {
                                sale.updated_at = new Intl.DateTimeFormat("en-US", options).format(updatedAt);
                            }
                        }

                        return fetchBookings(sale)
                            .then(bookingList => {
                                sale.bookingList = bookingList;
                                return sale;
                            });
                    });

                    const resolvedPendingSales = await Promise.all(pendingSalesPromises);

                    this.getPendingSales = resolvedPendingSales;
                    this.$forceUpdate();
                    console.log("123", this.getPendingSales);
                } catch (error) {
                    console.error(error);
                }
            },
        },
        outlets: {
            query: GET_ALL_OUTLET_QUERY,
        },
        staffById: {
            query: GET_STAFF_QUERY,
            variables() {
                return {
                    id: this.$session.get("user_id") * 1
                }
            },
            result() {
                var outlets = []
                var filteredoutlets = []
                for (let i = 0; i < this.staffById.staff_outlet.length; i++) {
                    outlets.push(this.staffById.staff_outlet[i].outlet_id.toString())
                }
                outlets.forEach(o => {
                    let outlet = {}
                    outlet.id = o
                    outlet.name = this.outlets.filter(outlet => outlet.id == o)[0].name
                    filteredoutlets.push(outlet)
                })
                this.staff_outlet = filteredoutlets
                if (!this.$session.get("currentOutletInit")) this.$session.set('currentOutlet', this.staff_outlet[0].name)
                this.$session.set('currentOutletInit', true)
            }
        },
        settings: {
        query: queries.GET_SETTINGS_QUERY,
        result (){
            this.websiteName = this.settings.name
          if(this.settings.image_id != 0 && this.settings.image_id != null){
            const GET_IMAGE_QUERY = gql`
              query imageById($id: ID!) {
                  imageById(id:$id)  {
                    id
                    type
                    filename
                  }
              }
            `
            this.$apollo.query(
              { 
                query: GET_IMAGE_QUERY, 
                variables:{
                  id: this.settings.image_id * 1
                },
              }
            ).then(result => {
              if(result.data.imageById != null){ 
                this.imageURL = window.location.hostname === 'localhost'
  ? 'https://placekitten.com/200/200'
  : 'https://prod-app360.sfo3.digitaloceanspaces.com/' + result.data.imageById.filename;

              } else {
                console.log("Image not found")
              }
            });
          }
        }
      },
    },
    methods: {
        goBack() {
      this.$router.go(-1);
    },
        cancelVoid() {
            this.voidDialog = false
        },
        cancelPay() {
            this.payDialog = false
            this.splitPayment = false
            this.resetMethodPayment()
        },
        openVoidDialog(item) {
            this.voidDialog = true
            this.selectedSales = item
        },
        voidSales(selectedSales) {
            const VOID_SALES = gql`
            mutation voidSales($id:ID!){
                voidSales(id:$id)  {
                    id
                    voided_at
                }
            }
            `
            const CANCEL_BOOKING_QUERY = gql`
            mutation cancelBooking($id:ID!) {
                cancelBooking(id:$id)  {
                    booking_no
                }
            }
            `
            this.$apollo.mutate(
                {
                    mutation: VOID_SALES,
                    variables: {
                        id: selectedSales.id * 1,
                    },
                }
            ).then(result => {
                if (result.data.voidSales != null) {
                    console.log("void successfully")
                    selectedSales.booking_id.forEach(id => {
                        this.$apollo.mutate(
                            {
                                mutation: CANCEL_BOOKING_QUERY,
                                variables: {
                                    id: id,
                                },
                            }
                        ).then(result => {
                            if (result.data.cancelBooking != null) {
                                console.log("Cancel successfully")
                                this.voidDialog = false
                            } else {
                                console.log("Cancel failed")
                            }
                        });
                    })
                } else {
                    console.log("void failed")
                }
            });
        },
        payNow(item) {
            //build invoice
            this.payDialog = true
            this.selectedSales = item
            this.selectedSales.amount = parseFloat(this.selectedSales.amount).toFixed(2)
        },
        approvedSales() {
            const APPROVE_SALES = gql`
            mutation approveSales($id:ID!){
                approveSales(id:$id)  {
                    id
                    updated_at
                }
            }
            `
            console.log("pay",this.selectedSales)
            this.$apollo.mutate(
                {
                    mutation: APPROVE_SALES,
                    variables: {
                        id: this.selectedSales.id * 1,
                    },
                }
            ).then(async result => {
                if (result.data.approveSales != null) {
                    console.log("pay successfully")
                    let createdSalesId = result.data.approveSales.id
                    const CREATE_PAYMENT_METHOD_SALES = gql`
                        mutation CreatePaymentMethodSales($salesId: Int!, $paymentMethodId: Int!, $amount: Float!) {
                        createPaymentMethodSales(salesId: $salesId, paymentMethodId: $paymentMethodId, amount: $amount) {
                            id
                            amount
                        }
                        }
                    `;

                    if (this.splitPayment) {
                        for (let i = 0; i < this.paymentMethodsList.length; i++) {
                            const pMethod = this.paymentMethodsList[i];
                            if (pMethod.amount > 0) {
                                this.$apollo.mutate({
                                    mutation: CREATE_PAYMENT_METHOD_SALES,
                                    variables: {
                                        paymentMethodId: parseInt(pMethod.id),
                                        salesId: parseInt(createdSalesId),
                                        amount: parseFloat(pMethod.amount * 1),
                                    },
                                }).catch(error => {
                                    console.log(JSON.stringify(error, null, 2));
                                });
                            }
                        }
                    } else if (!this.splitPayment) {
                        this.$apollo.mutate({
                            mutation: CREATE_PAYMENT_METHOD_SALES,
                            variables: {
                                paymentMethodId: parseInt(this.selectedPaymentMethod.id),
                                salesId: parseInt(createdSalesId),
                                amount: parseFloat(this.selectedSales.amount),
                            },
                        }).catch(error => {
                            console.log(JSON.stringify(error, null, 2));
                        });
                    }
                    try {
                        const { data } = await this.$apollo.mutate({
                            mutation: queries.CREATE_INVOICE_MUTATION,
                            variables: {
                                id: parseInt(this.selectedSales.id),
                            },
                        });
                        console.log('Invoice created:', data.createInvoiceBySalesId);
                        const outletId = this.outlets.filter(o => o.name == this.$session.get("currentOutlet"))[0].id
                        pdfMake.vfs = pdfFonts.pdfMake.vfs;
                        const outletDetails = await this.$apollo.query({
                            query: queries.GET_OUTLET_QUERY,
                            variables: { id: outletId * 1 },
                        });
                        const subtotal = this.selectedSales.bookingList.reduce((acc, booking) => {
                            const amount = booking.amount;
                            const packageTransactionId = booking.package_transaction_id;

                            if (packageTransactionId) {
                                return acc;
                            } else {
                                return acc + amount;
                            }
                        }, 0);
                        console.log('subtotal created:', subtotal);
                        const discount = this.selectedSales.amount * 1 - subtotal - (this.selectedSales.tax_amount * 1 || 0);
                        console.log('discount:', discount);
                        const invoiceData = {
                            invoice_no: data.createInvoiceBySalesId.invoice_no,
                            date: new Date(data.createInvoiceBySalesId.updated_at * 1).toLocaleDateString('en-US'),
                            customerName: this.selectedSales.User.user_fullname,
                            customerContact: this.selectedSales.User.user_contact,
                            outletAddress: { ...outletDetails.data.outletById },
                            subtotal: subtotal,//calc totol product
                            tax: Number(this.selectedSales.tax_amount || 0).toFixed(2),
                            discount: Number(discount).toFixed(2),//calc total - sub - tax
                            total: Number(this.selectedSales.amount).toFixed(2),
                            item: [...this.selectedSales.bookingList]
                        }

                        const addressLines = [
                            invoiceData.outletAddress.name,
                            invoiceData.outletAddress.address1,
                            invoiceData.outletAddress.address2,
                            invoiceData.outletAddress.address3,
                            `${invoiceData.outletAddress.postcode} ${invoiceData.outletAddress.city}`,
                            invoiceData.outletAddress.state,
                            invoiceData.outletAddress.country
                        ];

                        // Filter out empty address lines and join with commas
                        const filteredAddress = addressLines
                            .filter(line => line !== '')
                            .join(',\n');
                        
                        const imageData = await this.fetchImageData();

                        const documentDefinition = {
                            layout: 'fullWidth',
                            content: [
                                {
                                    columns: [
                                        // Left column
                                        {
                                        width: '50%',
                                        stack: [
                                            imageData && { image: `data:image/jpeg;base64,${imageData}`, width: 100 },
                                            { text: this.websiteName, style: 'header' },
                                            { text: `Invoice#${invoiceData.invoice_no}`, style: 'subheader' },
                                        ].filter(Boolean),
                                        style: 'leftColumn'
                                        },
                                        // Right column
                                        {
                                            width: '50%',
                                            stack: [
                                                { text: `${invoiceData.date}`, style: 'subheader', alignment: 'right' },
                                            ],
                                            style: 'rightColumn'
                                        }
                                    ]
                                },

                                {
                                    columns: [
                                        // Left column
                                        {
                                            width: '50%',
                                            stack: [

                                                { text: `Customer Name: ${invoiceData.customerName}` },
                                                { text: `Customer Contact: ${invoiceData.customerContact}` },
                                            ],
                                            style: 'leftColumn'
                                        },
                                        // Right column
                                        {
                                            width: '50%',
                                            stack: [
                                                { text: `${filteredAddress}`, alignment: 'right' }
                                            ],
                                            style: 'rightColumn'
                                        }
                                    ]
                                },
                                { text: 'Items', style: 'subheader' },
                                {
                                    table: {
                                        headerRows: 1,
                                        widths: ['*', 'auto'],
                                        style: 'bottomMargin',
                                        body: [
                                            [
                                                { text: 'Item Name', style: 'tableHeader' },
                                                { text: 'Price (RM)', style: 'tableHeader' },

                                            ],
                                            ...invoiceData.item.map(item => [
                                                {text: `${item.service_name || item.resource_type_name} (${item.staff_id && this.staffs.find(s => s.id == item.staff_id)?.fullname || ''}) \n ${this.formatDate(item.booked_date)} ${this.formatTime(item.from_time)} (${item.duration} mins)`},
                                                { text: `${!item.package_transaction_id ?Number(item.amount).toFixed(2):"0.00"}`, alignment: 'right' },
                                            ])
                                        ]
                                    }
                                },
                                { text: `Subtotal: RM ${invoiceData.subtotal}`, alignment: 'right' },
                                { text: `Tax: RM ${invoiceData.tax}`, alignment: 'right' },
                                { text: `Discount: -RM ${invoiceData.discount}`, alignment: 'right' },
                                { text: `Total: RM ${invoiceData.total}`, style: 'total', alignment: 'right' },
                            ],
                            styles: {
                                header: {
                                    fontSize: 24,
                                    bold: true,
                                    margin: [0, 0, 0, 10],
                                    decoration: 'underline'
                                },
                                subheader: {
                                    fontSize: 14,
                                    bold: true,
                                    margin: [0, 10, 0, 5]
                                },
                                tableHeader: {
                                    bold: true,
                                    fillColor: '#f2f2f2'
                                },
                                total: {
                                    fontSize: 14,
                                    bold: true,
                                    margin: [0, 10, 0, 5]
                                },
                                leftColumn: {
                                    margin: [0, 10, 0, 0]
                                },
                                rightColumn: {
                                    margin: [0, 10, 0, 0]
                                },
                                bottomMargin: {
                                    margin: [0, 0, 0, 10]
                                }
                            }
                        };
                        this.overlay = true
                        const fileName = data.createInvoiceBySalesId.invoice_no;
                        // pdfMake.createPdf(documentDefinition).open();
                        const invoicpdf = pdfMake.createPdf(documentDefinition);
                        this.uploadPdf(invoicpdf, fileName, 'booking', parseInt(this.selectedSales.id));
                    } catch (error) {
                        console.error(error);
                        console.log(JSON.stringify(error, null, 2));
                        // 根据需要处理错误
                    }

                    this.payDialog = false
                } else {
                    console.log("pay failed")
                }
            })
        },
        updateCurrentOutlet(value) {
            this.currentOutlet = value; // Update the currentOutlet value when selection changes
            this.$session.set("currentOutlet", value); // Update the session value
        },
        addCashAmount(value) {
            this.paymentMethodsList.forEach(m => {
                if (m.name === "cash") {
                    var amount = m.amount * 1
                    amount += value * 1
                    m.amount = amount
                }
            })
            this.calcRemainBalance()
        },
        resetMethodPayment() {
            this.paymentMethodsList.forEach(m => {
                m.amount = "0.00"
            })
            this.remainBalance = 0
            if (this.splitPayment) this.remainBalance = this.selectedSales.amount * 1
        },
        calcRemainBalance() {
            this.remainBalance = this.selectedSales.amount * 1
            this.paymentMethodsList.forEach(m => {
                var value = parseFloat(m.amount)
                if (!isNaN(value) && value >= 0) {
                    this.remainBalance -= value * 1
                }
                else {
                    value = 0.00
                }
                m.amount = value.toFixed(2)
            })
        },
        async uploadPdf(invoicpdf, name, type, salesID) {
            invoicpdf.getBlob((blob) => {
                const formData = new FormData();
                formData.append('pdf', blob, name + '.pdf');
                axios.post(process.env.VUE_APP_SERVER_DOMAIN + 'upload-pdf', formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                    .then(() => {
                        this.$apollo.mutate(
                            {
                                mutation: queries.CREATE_INVOICE_PDF_MUTATION,
                                variables: {
                                    type: type,
                                    filename: 'service_oasis/' + name,
                                    salesID: salesID
                                },
                            }
                        ).then(() => {
                            this.pdfSalesIid = parseInt(salesID)
                            this.pdfUploaded = true
                            this.overlay = false
                            this.openPDFDialog()
                        }).catch((err) => {
                            console.error('Error uploading PDF1:', err);
                            this.pdfUploaded = false
                            this.overlay = false
                            this.openPDFDialog()
                        });
                    })
                    .catch((error) => {
                        this.overlay = false
                        console.log(JSON.stringify(error));
                        console.error('PDF upload failed', error);
                    });
            });
        },
        formatDate(value) {
            const date = new Date(value * 1);
            // Customize the date formatting based on your requirements
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: "Asia/Singapore",
            });
            return formattedDate;
        },
        formatTime(value) {
            const time = new Date(1620691200000+value*1);
            var first_hour = time.getUTCHours()
            var first_minute = time.getMinutes()
            return first_hour.toString() + ":" + (first_minute > 9 ? first_minute : "0"+first_minute.toString())+" "+ (first_hour>12?"pm":"am");
        },
        openPDFDialog() {
            this.pdfDialog = true
        },
        closePDFDialog() {
            this.pdfDialog = false
            this.pdfUploaded = false
            this.pdfSalesIid = null
        },
        async downloadPdf(){
        try{
          const result = await this.$apollo.query({
                query: queries.GET_SALE,
                variables: {
                  id: this.pdfSalesIid * 1,
          }})
          window.open("https://prod-app360.sfo3.digitaloceanspaces.com/"+result.data.sales.pdfFile.filename+".pdf", '_blank');
        }catch(error){
          console.log(error);
          console.log(JSON.stringify(error, null, 2));
        }
      },
      async fetchImageData() {
        try {
          const response = await axios.get(this.imageURL, {
            responseType: 'arraybuffer', // Set the response type to arraybuffer
          });

          const imageData = Buffer.from(response.data, 'binary').toString('base64'); // Convert the image data to base64

          return imageData;
        } catch (error) {
          console.error('Error fetching the image:', error);
          return null;
        }
      },
    },
    mounted() {
        this.currentOutlet = this.$session.get("currentOutlet"); // Set the initial value from session
    },
    filters: {
        formatDate(value) {
            const date = new Date(value * 1);
            // Customize the date formatting based on your requirements
            const formattedDate = date.toLocaleDateString('en-US', {
                year: 'numeric',
                month: 'long',
                day: 'numeric',
                timeZone: "Asia/Singapore",
            });
            return formattedDate;
        },
        formatTime(value) {
            const time = new Date(1620691200000+value*1);
            var first_hour = time.getUTCHours()
            var first_minute = time.getMinutes()
            return first_hour.toString() + ":" + (first_minute > 9 ? first_minute : "0"+first_minute.toString())+" "+ (first_hour>12?"pm":"am");
        },
        formatPrice(value) {
            return Number(value).toFixed(2);
        }
    },
    created(){
        this.$apollo.query({ query: queries.GET_ALL_PERMISSIONS_QUERY }).then(async result => {
        this.getAllPermissions = await result.data.getAllPermissions;
        this.$apollo.query(
            {
            query: queries.GET_STAFF_ROLES,
            variables: {
                staffId: this.$session.get("user_id") * 1
            }
            }
        ).then(async result => {
            this.getStaffRoles = await result.data.getStaffRoles;
            this.allPermissionsName = [];
            this.getStaffRoles.forEach(role => {
            role.role.RolePermission.forEach((item) => {
                const name = item.permission.name;
                this.allPermissionsName.push(name);
            });
            })
            this.$session.set('staffPermission', this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName));
            this.staffPermission = await this.$generalFunctions.generatePermissionArray(this.getAllPermissions, this.allPermissionsName)
        })
        })
    }
};
</script>
  